.grants #title {
    background-color: #B3F8FD;
}

#header .schema {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 450px;
    margin-bottom: 70px;
}

#header .body {
    grid-column: 1;
}

#header .logo {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    left: calc(50% + 50px);
    top: 120px;
}

#header .logo a {
    padding: 8px 30px;
    background-color: #0F767E;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 200px;
    text-transform: uppercase;
    text-decoration: none;
    float: left;
    margin-top: 20px;
}

#header .logo a:hover {
    color: #000000;
}

#header .title {
    color: #0F767E;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
    padding-top: 72px;
}

#header .subtitle {
    color: #05373D;
    font-size: 24px;
    font-weight: 700;
    line-height: 35.5px;
    text-transform: uppercase;
}

#header .text {
    color: #05373D;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.5px;
    width: 98%;
    padding-bottom: 20px;
    padding-top: 20px;
}

#header .text:last-child {
    padding-top: 0px;
}

#header .text a {
    color: #0F767E;
}

#header .text a:hover {
    color: #00bccd;
}

#header .background {
    text-align: center;
    min-height: 350px;
    bottom: 300px;
}

#header .logo-title {
    color: #0F767E;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.5px;
    width: 55%;
    text-align: center;
    align-items: center;
    margin-top: 30px;
}

#header .highlighted {
    background-color: #C1F7FC;
}

#header .title-button {
    margin-bottom: 50px;
}

#header .title-button a {
    border-radius: 20px;
    background: #0F767E;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-right: 20px;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
}

#call-calendar {
    background-color: #eff4f5;
    padding-top: 100px;
    padding-bottom: 100px;
}

#call-calendar .content>.title {
    font-size: 50px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 11px;
    text-transform: uppercase;
    padding-left: 160px;
}

#call-calendar .dates {
    background-color: #dee8e9;
    width: 1200px;
    margin: auto;
    border-radius: 15px;
    height: 440px;
}

#call-calendar .content .dates .title {
    color: #05373D;
    font-size: 23px;
    font-weight: 700;
    line-height: 27.5px;
    padding-top: 47px;
    padding-left: 140px;
}

#call-calendar .content .dates ul {
    padding-top: 30px;
    list-style: none;
    padding-left: 140px;
    padding-right: 140px;
    padding-bottom: 100px;
}

#call-calendar .content .dates ul li {
    border-bottom: 1px solid #CDCDCD;
    padding-top: 12px;
    padding-bottom: 12px;
}

#call-calendar .content .dates ul li:last-child {
    border-bottom: none;
}

#call-calendar .content .dates ul .date {
    color: #0F767E;
    font-size: 18px;
    font-weight: 600;
    line-height: 23.5px;
    float: left;
    width: 200px;
}

#call-calendar .content .dates ul .text {
    color: #5E6162;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.5px;
}

#current-calls-objectives {
    background: url(../../images/current-calls/current_calls_bg_2.svg) no-repeat;
    background-color: #05373D;
    background-size: contain;
    background-position: left;
    text-align: center;
    min-height: 500px;
}

#current-calls-objectives .content .title {
    margin-top: 7%;
    margin-bottom: 2%;
    color: #A0F9FF;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
}

#current-calls-objectives .content .text {
    max-width: 80%;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
}

#current-calls-objectives .content .text2 {
    max-width: 80%;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    line-height: 23.5px;
    margin-top: 20px;
    margin-bottom: 50px;
}

#current-calls-objectives .content .full-call-text {
    padding: 8px 30px;
    background-color: #0F767E;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 200px;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 20px;
}

#current-calls-objectives .content .full-call-text:hover {
    color: #000000;
}

.application-banner-mar-2024 {
    margin-top: 40px;
    margin-bottom: 60px;
}

.application-banner-mar-2024 .open-calls-content {
    padding-top: 40px;
}

.application-banner-mar-2024 .open-calls-content .open-calls-2-mar-2024 {
    margin-top: -53px;
}

.objective-button {
    margin-top: 40px;
    text-align: center;
}

#apply-btn {
    border-radius: 20px;
    background: #0F767E;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-left: 20px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

#apply-btn:hover {
    color: #000000;
}

#apply-btn:active {
    filter: brightness(90%);
}

#guide-link {
    color: #11B5C0;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.5px;
    text-decoration-line: underline;
    cursor: pointer;
}

#guide-link:hover {
    color: #A0F9FF;
}

#guide-link:active {
    filter: brightness(90%);
}

#more-info {
    background: url(../../images/current-calls/current_calls_bg_1.svg) no-repeat;
    background-size: contain;
    background-position: left;
    background-color: #11b5c0;
    padding-top: 86px;
    padding-bottom: 86px;
}

#more-info>.title {
    color: #FFF;
    font-size: 32px;
    font-weight: 500;
    line-height: 35.5px;
    text-align: center;
}

#more-info .content > .text {
    max-width: 80%;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 30px;
}

#more-info .content > .text a{
    color: white;
    font-weight: bold;
}

#more-info .elements {
    display: block;
    width: 1200px;
    margin: auto;
}

#more-info .elements-mobile .content {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

#more-info .elements-mobile .content .element {
    flex: 1;
    margin: 0 10px;
    border-right: solid 1px white;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
}

#more-info .elements-mobile .content .element:last-child {
    border-right: 0px;
}

#more-info .elements-mobile .content .element img {
    padding-bottom: 30px;
}

#more-info .elements-mobile .content .element .title {
    color: #05373D;
    font-size: 23px;
    font-weight: 700;
    line-height: 27.5px;
    margin-bottom: 20px;
}

#more-info .elements-mobile .content .element .text {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 30px;
}

#more-info .elements-mobile .content .element a {
    padding: 8px 30px;
    background-color: #0F767E;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 200px;
    text-transform: uppercase;
    text-decoration: none;
}

#submission-title {
    margin-top: 9px;
}

.breaker {
    width: 100%;
    height: 1px;
    background-color: #C4C4C4;
}

#information-buttons:hover {
    color: #000000;
}

#application-section-4 {
    margin-top: -30px;
}

#application-section-5 ul li {
    line-height: 25px;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .schema .body {
        width: 330px !important;
        margin-left: 15px;
        margin-bottom: 30px;
        margin-top: 150px;
        text-align: center;
    }

    .schema .body .text {
        margin-left: 33px;
    }

    .schema .logo {
        margin-left: -243px;
        margin-top: 270px;
    }

    .schema .logo .background img {
        width: 300px;
        height: 300px;
    }

    #header .logo-title {
        color: #0F767E;
        font-size: 24px;
        font-weight: 700;
        line-height: 33.5px;
        width: 95%;
        text-align: center;
        align-items: center;
        margin-top: -40px;
    }
    
    #header .highlighted {
        background-color: #C1F7FC;
    }
    
    #header .title-button {
        margin-bottom: 50px;
    }
    
    #header .title-button a {
        border-radius: 20px;
        background: #0F767E;
        border: none;
        cursor: pointer;
        padding: 10px 30px;
        box-sizing: border-box;
        margin-right: 20px;
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
    }

    #current-calls-objectives .content {
        padding-top: 30px;
    }

    #current-calls-objectives .content .title {
        margin-bottom: 30px;
    }

    #current-calls-objectives .content .objective-button {
        margin-bottom: 30px;
    }

    .application-banner .content .open-calls-content {
        flex-direction: column;
        align-items: center;
    }

    .application-banner .content .open-calls-content .open-calls-1 {
        padding-top: 20px;
        width: 150% !important;
    }

    #application-1,
    #application-2 {
        width: 330px !important;
        margin-left: 0px;
    }

    #application-1 {
        margin-left: 105px;
        margin-bottom: -20px;
    }

    #application-1 img {
        display: none;
    }

    #application-section-4 {
        margin-top: 0px;
    }
    
    #application-section-5 ul li {
        line-height: 26px;
    }

    .application-banner .content .open-calls-content .open-calls-2 {
        padding-top: 60px;
        margin-bottom: -30px;
    }

    #more-info .title {
        margin-top: -20px;
    }

    #more-info .elements-mobile .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    #more-info .elements-mobile .content .element {
        border-bottom: solid 1px white;
        border-right: 0px;
        padding-bottom: 40px;
    }
    
    #more-info .elements-mobile .content .element:last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    #more-info .elements-mobile .content .element img {
        padding-top: 30px;
    }

    #more-info .elements-mobile .content .element .title {
        padding-top: 40px;
        color: #05373D;
        font-size: 23px;
        font-weight: 700;
        line-height: 27.5px;
        margin-bottom: 10px;
    }

    #more-info .elements-mobile .content .element .text {
        color: #FFF;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
    }

    #more-info .elements-mobile .content .element a {
        padding: 8px 30px;
        background-color: #0F767E;
        color: white;
        font-size: 16px;
        font-weight: 600;
        border-radius: 200px;
        text-transform: uppercase;
        text-decoration: none;
    }

    #call-calendar {
        width: 100% !important;
        background-color: #eff4f5;
        padding-top: 50px;
        padding-bottom: 30px;
    }

    #call-calendar .content .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 11px;
        text-transform: uppercase;
        padding-left: 30px;
    }

    #call-calendar .dates {
        background-color: #dee8e9;
        width: 100% !important;
        margin: auto;
        border-radius: 15px;
        height: auto;
    }
    
    #call-calendar .content .dates .title {
        color: #05373D;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        padding-top: 35px;
        padding-left: 30px;
        letter-spacing: 7px;
    }

    #dates-table {
        margin-top: 20px;
        margin-left: 30px;
    }
    
    #call-calendar .content .dates ul {
        list-style: none;
        padding-left: 30px;
        width: 84% !important;
        list-style: none;
        padding: 0;
    }
    
    #call-calendar .content .dates ul li {
        border-bottom: 1px solid #CDCDCD;
        display: flex;
        flex-direction: column;
    }

    #call-calendar .content .dates ul li:last-child {
        border-bottom: none;
        padding-bottom: 30px;
    }
    
    #call-calendar .content .dates ul .date {
        color: #0F767E;
        font-size: 18px;
        font-weight: 600;
        line-height: 23.5px;
        float: left;
        width: 200px;
    }
    
    #call-calendar .content .dates ul .text {
        color: #5E6162;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.5px;
        margin-top: 5px;
        width: 330px;
    }

}