.header-consortium {
    background-color: #9ad3e5;
}

.breadcrumb-about {
    height: calc(100vh - 230px);
    background: url(../../images/about/map3.svg) no-repeat;
    background-size: 900px 900px;
    background-position: right;
    margin-top: 0px !important;
    padding-top: 20px; 
}

.breadcrumb-about .text {
    color: #003A41;
    font-size: 27px;
    font-weight: 500;
    line-height: 33.5px;
    position: relative;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.breadcrumb-about .text .content {
    width: 450px !important;
}

#partners {
    width: 100%;
    background-color: white;
}

#partners .partner {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 100px;
    padding-top: 100px;
}

#partners .partner:last-child {
    border-bottom: none;
}

#partners .partner .header {
    padding-bottom: 20px;
}

#partners .partner #header-sp {
    margin-left: -28px;
}

#partners .partner .title {
    color: #11B5C0;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
    padding-top: 30px;
}

#partners .partner .body {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    padding-bottom: 20px;
}

#partners .partner .footer a {
    padding: 8px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    background: var(--Verde, #0F767E);
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

#partners .partner .footer a:hover {
    color: #000000;
}

#partners .partner .footer a:active {
    filter: brightness(90%);
}

.partner-separator {
    margin-left: 150px;
    margin-right: 150px;
    border-bottom: solid 1px #05373D;
}

@media only screen and (max-width: 768px) {

    .content {
        max-width: 100%;
        margin: 0 auto;
        padding: 10px;
    }

    .content .title-subtitle {
        color: #0f767e;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        margin-top: 30px;
    }

    .content .title-title {
        color: #05373d;
        text-align: left;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 6px;
        position: relative;
        margin-top: -10px;
    }

    .breadcrumb-about {
        background: url(../../images/about/map3.svg) no-repeat;
        background-size: 95%;
        background-position: top right;
        margin-top: 0px !important;
        padding-top: 20px;
        height: 420px;
    }

    .breadcrumb-about .content {
        padding-bottom: 10px;
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    .breadcrumb-about .text {
        padding-top: 10px;
    }

    .breadcrumb-about .text #consortium-intro {
        text-align: center;
        color: #003A41;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin-top: -300px;
    }

    #partners {
        max-width: 100%;
        background-color: white;
    }

    #partners .partner {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    
    #partners .partner:last-child {
        border-bottom: none;
    }
    
    #partners .partner .header {
        padding-bottom: 20px;
    }
    
    #partners .partner #header-sp {
        margin-left: -28px;
    }

    .partner .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .partner .header img {
        max-width: 200px;
        max-height: 200px;
    }
    
    #partners .partner .title {
        text-align: center;
        color: #11B5C0;
        font-size: 32px;
        font-weight: 600;
        line-height: 35.5px;
        padding-top: 30px;
    }
    
    #partners .partner .body {
        text-align: center;
        font-family: Raleway;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
        padding-bottom: 20px;
    }

    #partners .partner .footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
