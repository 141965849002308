.header-contact {
    background-color: #9ad3e5;
}

.contact .title-logo {
    position: absolute;
    left: 1050px;
    top: 140px;
}

.contact-body #breadcrumb {
    color: #5E6162;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.5px;
    margin-top: 2%;
    margin-bottom: 60px;
}

.contact-body .email-addresses {
    text-align: center;
}

.contact-body .email-addresses .introduction #title {
    font-family: Raleway;
    font-size: 43px;
    font-weight: 600;
    line-height: 35.5px;
    color: #0F767E;
    background-color: #EFF4F5;
    padding-top: 40px;
}

.contact-body .email-addresses .introduction #subtitle {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    color: #05373D;
    width: 65%;
    margin: 0 auto;
    margin-top: -40px;
}

.contact-body .email-addresses .cards-container {
    margin-top: 70px;
    display: flex;
    gap: 20px;
}

.contact-body .email-addresses .cards-container .card {
    padding: 20px;
    flex: 1;
    text-align: left;
    padding-left: 38px;
    padding-right: 38px;
    padding-bottom: 50px;
}

#card-1 {
    border: 1px solid #003A41;
}

#card-2 {
    border: 1px solid #017581;
}

#card-3 {
    border: 1px solid #08B0C0;
}

.contact-body .email-addresses .cards-container .card #title {
    display: flex;
    background-color: #EFF4F5;
    padding-top: 40px;
}

.contact-body .email-addresses .cards-container #circle-1 {
    position: absolute;
    left: 124px;
    top: 705px;
    z-index: 1000;
}

.contact-body .email-addresses .cards-container #circle-2 {
    position: absolute;
    left: 544px;
    top: 705px;
    z-index: 1000;
}

.contact-body .email-addresses .cards-container #circle-3 {
    position: absolute;
    left: 964px;
    top: 705px;
    z-index: 1000;
}

.contact-body .email-addresses .cards-container .card #body {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.5px;
    color: var(--gris, #5E6162);
    margin-top: -40px;
    margin-bottom: 40px;
}

.contact-body .email-addresses .cards-container .card #button-1 a {
    background-color: #003A41;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 40px;
}

.contact-body .email-addresses .cards-container .card #button-2 a {
    background-color: #017581;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 26px;
}

.contact-body .email-addresses .cards-container .card #button-3 a {
    background-color: #08B0C0;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 8px;
}

.contact-body .email-addresses .cards-container .card #button-1 a:hover,
.contact-body .email-addresses .cards-container .card #button-2 a:hover,
.contact-body .email-addresses .cards-container .card #button-3 a:hover {
    color: #000000;
}

.contact-body .email-addresses .cards-container .card #button-1 a:active,
.contact-body .email-addresses .cards-container .card #button-2 a:active,
.contact-body .email-addresses .cards-container .card #button-3 a:active {
    filter: brightness(90%);
}

.contact-body .email-addresses .footer {
    width: 90%;
    padding-top: 90px;
    font-family: Raleway;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    color: #0F767E;
    margin: 0 auto;
}

.contact-body .email-addresses .social-media {
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
}

.contact-body .email-addresses .social-media #social-text {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 4.5px;
    text-transform: uppercase;
    color: #05373D;
    padding-right: 20px;
    margin-left: 445px;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .page-container {
        max-width: 100% !important;
    }

    .contact .title-logo {
        position: absolute;
        left: 130px;
        top: 410px;
    }

    .contact-body .email-addresses .introduction {
        margin-top: -160px;
    }

    .contact-body .email-addresses .cards-container {
        margin-top: 70px;
        flex-direction: column;
    }

    .contact-body .email-addresses .cards-container .card {
        padding: 20px;
        flex: 1;
        text-align: left;
        padding-left: 38px;
        padding-right: 38px;
        padding-bottom: 50px;
        margin: 0 auto;
        width: 90%;
    }

    .contact-body .email-addresses .cards-container #circle-1 {
        position: absolute;
        left: 15.5px;
        top: 953px;
        z-index: 1000;
    }
    
    .contact-body .email-addresses .cards-container #circle-2 {
        position: absolute;
        left: 15.5px;
        top: 1340px;
        z-index: 1000;
    }
    
    .contact-body .email-addresses .cards-container #circle-3 {
        position: absolute;
        left: 15.5px;
        top: 1749px;
        z-index: 1000;
    }

    .contact-body .email-addresses .footer {
        width: 90%;
        padding-top: 70px;
        font-family: Raleway;
        font-size: 26px;
        font-weight: 600;
        line-height: 30px;
        color: #0F767E;
        margin: 0 auto;
        padding-bottom: 80px;
    }
    
    .contact-body .email-addresses .social-media {
        position: absolute;
        top: 2275px;
        left: -432px;
    }
    
    .contact-body .email-addresses .social-media #social-text {
        font-family: Raleway;
        font-size: 18px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 4.5px;
        text-transform: uppercase;
        color: #05373D;
        padding-right: 20px;
        margin-left: 445px;
    }

    .contact-body .email-addresses .cards-container .card #button-1 a {
        background-color: #003A41;
        color: #fff;
        border: none;
        padding: 8px 30px;
        border-radius: 25px;
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: 0px;
    }
    
    .contact-body .email-addresses .cards-container .card #button-2 a {
        background-color: #017581;
        color: #fff;
        border: none;
        padding: 8px 20px;
        border-radius: 25px;
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: 0px;
    }
    
    .contact-body .email-addresses .cards-container .card #button-3 a {
        background-color: #08B0C0;
        color: #fff;
        border: none;
        padding: 8px 7px;
        border-radius: 25px;
        cursor: pointer;
        font-size: 15px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: 0px;
    }

}