footer {
    background: #ffffff;
}

.footer-text {
    color: #05373d;
    text-align: left;
    position: relative;
}

.sub-footer-top {
    background: #0F767E;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
}

.sub-footer-col-1 {
    width: 45%;
    float: left;
    padding-right: 5%;
}

.sub-footer-col-2 {
    width: 52%;
    float: right;
}

#sub-footer-col-text {
    color: #ffffff;
}

.social-media-icon {
    margin-bottom: 3px;
}

.sub-footer-col-buttons {
    margin-top: 5%;
    display: flex;
}

.btn-social-media {
    border-radius: 50%;
    border: none;
    background-color: #ffffff;
    color: #000000;
    border: 0;
    margin-right: 5%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
    font-size: 22px;
}

.btn-social-media:hover {
    color: #25B8C7;
}

.btn-social-media:active {
    filter: brightness(90%);
}

#sub-footer-col-footer {
    text-align: center;
    margin-top: 20%;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
}

.sub-footer-col-newsletter-title {
    color: #A0F9FF;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}

.sub-footer-col-newsletter-subtext {
    margin-top: 1%;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
}

.sub-footer-col-title {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;
}

.sub-footer-col-menu {
    float: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
    width: 120%;
}

.sub-footer-col-menu ul {
    margin-left: 0px;
    padding-left: 0px;
}

.sub-footer-col-menu ul li {
    list-style: none;
    display: inline;
    margin-right: 20px;
}

.sub-footer-col-menu ul li a {
    font-size: 15px;
    font-weight: 500;
}

.sub-footer-col-menu ul li ul {
    display: none;
}

.sub-footer-col-contact {
    margin-top: 15%;
}

#sub-footer-col-menu-link {
    color: #FFFFFF;
    text-decoration-line: none;
}

.sub-footer-col-newsletter {
    margin-top: 40px;
    float: left;
    width: 100%;
}

#sub-footer-col-newsletter-subscribe-form {
    margin-top: 2%;
    display: flex;
    text-align: left;
    float: left;
    width: 80%;
}

#sub-footer-col-newsletter-subscribe-btn {
    border-radius: 20px;
    background: #25b8c7;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border: none;
    text-decoration-line: none;
}

#sub-footer-col-newsletter-subscribe-btn:hover {
    color: #000000;
}

#sub-footer-col-newsletter-subscribe-btn:active {
    filter: brightness(90%);
}

#sub-footer-col-newsletter-subscribe-btn-2 {
    border-radius: 20px;
    background: #25b8c7;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-left: -50px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

#sub-footer-col-newsletter-subscribe-btn-2:hover {
    color: #000000;
}

#sub-footer-col-newsletter-subscribe-btn-2:active {
    filter: brightness(90%);
}

#sub-footer-col-newsletter-subscribe-btn-3 {
    border-radius: 20px;
    background: #25b8c7;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

#sub-footer-col-newsletter-subscribe-btn-3:hover {
    color: #000000;
}

#sub-footer-col-newsletter-subscribe-btn-3:active {
    filter: brightness(90%);
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown a svg {
    margin-left: 5px;
    margin-top: -2px;
}

.dropbtn {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    outline: none;
    text-decoration-line: none;
}

.dropdown-content {
    background-color: #125d65;
    display: none;
    position: absolute;
    min-width: 210px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    padding: 12px 16px;
    display: block;
    text-decoration: none;
    color: #ffffff;
}

.dropdown-content a:hover {
    background-color: #25b7c7bb;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .sub-footer-top{
        padding-top: 40px;
        padding-bottom: 50px;
    }

    .sub-footer-col-1,
    .sub-footer-col-2 {
        width: 86%;
        margin-left: 7%;
        float: none;
        padding-right: 0;
    }

    .sub-footer-col-2{
        margin-top: 35px;
    }

    #sub-footer-col-logo img {
        height: 150px;
        width: 150px;
    }

    #sub-footer-col-text {
        color: #ffffff;
        font-size: 14px;
    }

    .sub-footer-col-menu ul li{
        display: block;
        margin-right: 0px;
        margin-top: 20px;
        width: 100% !important;
    }

    #sub-footer-col-newsletter-subscribe-form{
        width: 100%;
        display: block;
    }

    #form-control{
        width: 100%;
    }

    #sub-footer-col-newsletter-subscribe-btn-2{
        float: left;
        margin-left: 0px;
        margin-top: 10px;
    }
}