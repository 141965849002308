.oss-platform #title {
    background-color: #EFF4F5 !important;
}

.oss-platform #title .box {
    background-color: #11B0C0 !important;
    border-radius: 15px 15px 15px 15px;
    height: 185px;
    gap: 0px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    background: url(../../images/oss-platform/title_background.svg) no-repeat;
    background-size: 500px;
    background-position: -140px -110px;
    margin-bottom: 50px;
}

.oss-platform #title .box .box-title h1 {
    font-family: Raleway;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 5px;
    text-align: left;
    color: #FFFFFF;
    text-transform: uppercase;
    width: 70%;
    padding-left: 60px;
}

.oss-platform #title .box .box-logo img {
    padding-right: 120px;
}

.oss-platform .introduction {
    margin-top: 120px;
}

.oss-platform .introduction .content {
    margin: 0 auto;
    max-width: 70%;
    padding-left: 50px;
}

.oss-platform .introduction .introduction-title h3 {
    font-family: Raleway;
    font-size: 29px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #0F767E;
}

.oss-platform .introduction .introduction-body h5 {
    padding-top: 10px;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: #05373D;
}

.oss-platform .platform {
    padding-top: 60px;
    background: url(../../images/oss-platform/platform_background.svg) no-repeat;
    background-size: 600px;
    background-position: 920px -100px;
}

.oss-platform .platform .container {
    background-color: #FFFFFF !important;
    background-size: 900px;
    background-position: right;
    border-radius: 15px;
    height: 435px;
    width: 97%;
    padding-top: 30px;
}

.oss-platform .platform .container .row-1 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 15px;
}

.oss-platform .platform .container .row-1 .title {
    width: 370px;
    text-align: center;
}

.oss-platform .platform .container .row-2 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 15px;
    padding-top: 20px;
}

.oss-platform .platform .container .platform-card {
    background: url(../../images/oss-platform/card_background.svg) no-repeat;
    background-position: 120px -170px;
    background-color: #2798A4 !important;
    border-radius: 15px;
    height: 175px;
    width: 370px;
    padding: 25px;
}

.oss-platform .platform .container .platform-card .row {
    display: flex;
    align-items: center;
}

.oss-platform .platform .container .platform-card .platform-card-title,
.oss-platform .platform .container .platform-card .platform-card-logo {
    flex: 1;
}

.oss-platform .platform .container .platform-card .platform-card-logo {
    margin-left: 140px;
}

.oss-platform .platform .container .platform-card #platform-card-logo-2 {
    margin-left: 30px;
}

.oss-platform .platform .container .platform-card #platform-card-title-2 {
    min-width: 220px;
}

.oss-platform .platform .container .platform-card a {
    text-decoration: none;
}

.oss-platform .platform .container .platform-card a .platform-card-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 32.45px;
    text-align: left;
    color: #FFFFFF;
    width: 200px;
    text-decoration: none;
    cursor: pointer;
}

.oss-platform .platform .container .platform-card a:hover .platform-card-title {
    color: #23e9ff;
    text-decoration: none;
}

.oss-platform .platform .container .platform-card a:active .platform-card-title {
    filter: brightness(90%);
}

.oss-platform .platform .container .platform-card .platform-card-body h5 {
    padding-top: 10px;
    font-size: 16.45px;
    font-weight: 400;
    line-height: 24.68px;
    text-align: left;
    color: #FFFFFF;
}

.oss-platform .footer h3 {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 600;
    line-height: 32.89px;
    text-align: center;
    color: #05373D;
}

.oss-platform .footer #footer-1 {
    padding-top: 70px;
    width: 65%;
    text-align: center;
    margin: 0 auto;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .oss-platform #title .box {
        background-size: 350px;
        background-position: -160px -10px;
    }

    .oss-platform #title .box .box-title h1 {
        font-family: Raleway;
        font-size: 26px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 5px;
        text-align: left;
        color: #FFFFFF;
        text-transform: uppercase;
        width: 70%;
        margin: 0 auto;
        margin-left: -30px;
    }

    .oss-platform #title .box .box-logo img {
        padding-left: 10px;
    }

    .oss-platform .introduction .content {
        margin: 0 auto;
        max-width: 80%;
        padding-left: 30px;
    }

    .introduction .content .introduction-title {
        padding-top: 20px;
    }

    .introduction .content .introduction-title,
    .introduction .content .introduction-body {
        width: 95%;
    }

    .oss-platform .platform .container {
        background-color: #FFFFFF !important;
        background-size: 900px;
        background-position: right;
        border-radius: 15px;
        height: 1260px;
        width: 97%;
        padding-top: 30px;
    }

    .oss-platform .platform .container .row-1,
    .oss-platform .platform .container .row-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
    }

    .oss-platform .platform .container .row-1 .platform-card {
        order: 2;
      }
      
    .oss-platform .platform .container .row-1 .title {
        order: 1;
    }

    .oss-platform .platform .container .row-1 .platform-card,
    .oss-platform .platform .container .row-2 .platform-card {
        background: url(../../images/oss-platform/card_background.svg) no-repeat;
        background-position: 120px -170px;
        background-color: #2798A4 !important;
        border-radius: 15px;
        height: 100%;
        width: 95%;
    }

}