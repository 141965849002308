.about-title-img img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.breadcrumb-about-page .content {
    margin-top: 2%;
}

.page-container #about-title {
    background-color: #9ad3e5;
    overflow: visible;
}

#content-about {
    padding-top: 100px;
}

#content-about #content-header .left {
    width: 670px !important;
    float: left;
}

#content-about #content-header .right {
    width: 570px !important;
    float: right;
    text-align: center;
}

#content-about .content-introduction-header {
    color: #0f767e;
    text-align: left;
    position: relative;
    width: 550px;
    margin-bottom: 40px;
}

#content-about #content-header .left h2 {
    color: #0F767E;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
    margin-bottom: 4%;
}

#content-about .content-introduction-body #introduction-step-1 {
    color: #05373D;
    font-size: 19px;
    font-weight: 600;
    line-height: 27.5px;
}

#content-about .content-introduction-body #introduction-step-2 {
    color: #11B5C0;
    font-size: 24px;
    line-height: 27.5px;
    font-weight: 600;
    margin-top: 5px;
    float: left;
    width: 100%;
}

#content-about .content-introduction-body #introduction-step-3 {
    color: var(--gris, #5E6162);
    font-size: 16px;
    font-weight: 400;
    line-height: 23.5px;
    margin-top: 6px;
    float: left;
    width: 100%;
}

#content-about .content-introduction-body #introduction-step-4 {
    display: flex;
    align-items: center;
}

#content-about #content-header .right img {
    margin-top: 70px;
}

#content-about .content-support {
    margin-top: 30px;
    background: url(../../images/about/about_background_1.png) no-repeat center bottom, #05373d;
    overflow: hidden;
    text-align: center;
}

#content-about .content-objectives {
    background: #b5e4e8;
    position: relative;
}

#content-about .content-objectives-header {
    margin-top: 80px;
    width: 500px !important;
    margin-left: calc(50% - 400px) !important;
    overflow: hidden;
    color: #05373d;
    text-align: left;
    position: relative;
}

#content-about .content-objectives-body {
    margin-top: 80px;
    text-align: center;
    margin-bottom: 80px;
}

#content-about .content-objectives-body img {
    max-width: 50%;
    height: auto;
}

#content-about .content-explanation {
    background: #eff4f5;
    border-radius: 15px;
    text-align: center;
    overflow: visible;
    margin-top: 20px;
    padding-bottom: 100px;
    padding-top: 100px;
    position: relative;
}

#content-about .content-explanation-logo {
    text-align: center;
    position: absolute;
    z-index: 2;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
}

#content-about .content-explanation .content {
    width: 1300px !important;
    background-color: #d3e3e6 !important;
    margin-left: calc(50% - 650px) !important;
    margin-left: auto;
    border-radius: 15px;
}

#content-about .content-support-body {
    margin-top: 140px;
    margin-left: 80px;
    margin-right: 80px;
}

#content-about #content-support-step-1 {
    color: #A0F9FF;
    text-align: center;
    font-size: 25.874px;
    font-weight: 500;
    line-height: 31.555px;
}

#content-about #content-support-step-2 {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
    margin-bottom: 140px;
}

#content-about h3 {
    color: #0F767E;
    text-align: center;
    font-size: 32px !important;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
    padding-top: 70px;
}

#content-about #explanation-step-1 {
    padding-top: 20px;
    color: #05373D;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.5px;
    padding-left: 80px;
    padding-right: 80px;
}

#content-about #explanation-step-2 {
    color: #5E6162;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.5px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;
}

#objectives {
    background-color: #b5e4e8 !important;
    padding-top: 100px;
    padding-bottom: 120px;
    text-align: left !important;
    margin-left: -100px;
}

#objectives .title {
    font-size: 34px;
    font-weight: 600;
    line-height: 35.5px;
    color: #05373D !important;
    width: 480px !important;
    margin-bottom: 36px;
}

#objectives .objectives-content {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
}

#objectives .objective {
    width: 750px;
    height: 166px;
    border-radius: 20px;
}

#objectives .objective.left {
    background-color: #1fbac4;
    width: 100% !important;
}

#objectives .objective.right {
    background-color: #edf9f9;
    margin-left: 100px;
    width: 100% !important;
}

#objectives .objective .image {
    width: 86px;
    height: 86px;
    background-repeat: no-repeat;
    float: left;
    margin-top: 40px;
}

#objectives .objective .image.image1 {
    background: url(../../images/about/1.svg);
    background-size: 86px 86px;
}

#objectives .objective .image.image2 {
    background: url(../../images/about/2.svg);
    background-size: 86px 86px;
}

#objectives .objective .image.image3 {
    background: url(../../images/about/3.svg);
    background-size: 86px 86px;
}

#objectives .objective .image.image4 {
    background: url(../../images/about/4.svg);
    background-size: 86px 86px;
}

#objectives .objective .image.image5 {
    background: url(../../images/about/5.svg);
    background-size: 86px 86px;
}

#objectives .objective.left .image {
    margin-left: 40px;
}

#objectives .objective.right .image {
    margin-right: 40px;
}

#objectives .objective .number {
    width: 40px;
    height: 40px;
    float: left;
    margin-left: 34px;
    background-color: white;
    border-radius: 20px;
    margin-top: 65px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #05373D;
}

#objectives .objective.right .number {
    margin-right: 34px;
    margin-left: 0px;
    background-color: #0F767E;
    color: white;
}

#objectives .objective .text {
    color: #003A41;
    font-size: 20px;
    line-height: 32px;
    float: left;
    width: 500px;
    margin-left: 20px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#objectives .objective.right .text {
    text-align: right;
    margin-right: 20px;
    margin-left: 170px;
    width: 47%;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .about-title-img img {
        display: none;
    }

    .breadcrumb-about-page .content {
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    #content-about {
        padding-top: 20px;
    }

    #content-about .content {
        display: block;
    }

    #content-about #content-header {
        width: 95%;
        margin: 0 auto;
    }

    #content-about #content-header .left {
        text-align: center;
        max-width: 100%;
    }

    #content-about #content-header .right img {
        display: none;
    }

    #content-about .content-explanation {
        text-align: center;
        background: #E1EDEF;
        margin: 0 auto;
        margin-top: 40px;
        width: 90%;
        height: 830px;
    }
    
    #content-about .content-explanation-logo {
        position: absolute;
        z-index: 2;
        height: auto;
        display: block;
        margin: 0 auto;
        top: 1px;
        transform: translate(-50%, -50%);
    }

    #content-about .content-explanation .content {
        width: 90%;
        background-color: #d3e3e6 !important;
    }

    #content-about #explanation-step {
        position: absolute;
        z-index: 2;
        margin-left: 500px;
        color: #0F767E;
        text-align: center;
        font-size: 30px !important;
        font-weight: 600;
        margin-top: -100px;
    }
    
    #content-about #explanation-step-1 {
        position: absolute;
        z-index: 2;
        margin-left: 415px;
        padding-top: 40px;
        color: #05373D;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 27.5px;
        width: 130%;
    }
    
    #content-about #explanation-step-2 {
        position: absolute;
        z-index: 2;
        margin-left: 415px;
        color: #5E6162;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24.5px;
        margin-top: 280px;
        width: 130%;
    }

    #content-about .content-support-body {
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;
    }
    
    #content-about #content-support-step-1 {
        color: #A0F9FF;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 31.555px;
    }
    
    #content-about #content-support-step-2 {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 23.5px;
        margin-bottom: 40px;
    }

    #objectives {
        max-width: 100% !important;
        margin-left: 0px;
        height: 710px;
    }

    #objectives .objectives-content {
        width: 90% !important;
        margin: 0 auto !important;
        margin-bottom: -90px !important;
    }

    #objectives .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #05373D !important;
        margin-top: -60px;
        margin-bottom: 36px;
        width: 100% !important;
    }   
    
    #objectives .objective {
        width: 97% !important;
        border-radius: 20px;
    }

    #objectives .objective.left {
        background-color: #1fbac4;
        height: 140px;
        width: 97% !important; /* I */
    }

    #left-3 {
        height: 120px !important;
    }
    
    #objectives .objective.right {
        background-color: #edf9f9;
        margin-left: 15px;
        height: 80px;
        width: 97% !important; /* I */
    }
    
    #objectives .objective .image {
        width: 30px;
        height: 30px;
        margin-top: 20px;
        background-repeat: no-repeat;
    }

    #objectives .objective.left .image {
        margin-left: 20px;
    }
    
    #objectives .objective.right .image {
        margin-top: -70px;
        margin-left: 290px;
    }
    
    #objectives .objective .image.image1 {
        background: url(../../images/about/1.svg);
        background-size: 30px 30px;
    }
    
    #objectives .objective .image.image2 {
        background: url(../../images/about/2.svg);
        background-size: 30px 30px;
    }
    
    #objectives .objective .image.image3 {
        background: url(../../images/about/3.svg);
        background-size: 30px 30px;
    }
    
    #objectives .objective .image.image4 {
        background: url(../../images/about/4.svg);
        background-size: 30px 30px;
    }
    
    #objectives .objective .image.image5 {
        background: url(../../images/about/5.svg);
        background-size: 30px 30px;
    }

    #objectives .objective.left #left-3-image {
        margin-top: 10px;
    }
    
    #objectives .objective.left .number {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 15px;
        background-color: white;
        border-radius: 20px;
        margin-top: 20px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #05373D;
    }

    #objectives .objective.left #left-3-number {
        margin-top: 10px;
        line-height: 24px;
    }

    #objectives .objective.right #right-4-number {
        line-height: 25px;
    }

    #objectives .objective.left #left-5-number {
        line-height: 26px;
    }
    
    #objectives .objective.left .text {
        color: #003A41;
        font-size: 16px;
        line-height: 20px;
        float: left;
        width: 190px !important;
        margin-left: 115px;
        position: relative;
        top: 16%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #objectives .objective.left #left-3-text {
        top: 10%;
    }

    #objectives .objective.left #left-5-text {
        top: -1%;
    }

    #objectives .objective.right .number {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 245px;
        background-color: #0F767E;
        border-radius: 20px;
        margin-top: -70px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: white;
    }

    #objectives .objective.right .text {
        margin-right: 110px;
        color: #003A41;
        font-size: 16px;
        line-height: 20px;
        float: right;
        width: 60%;
        position: relative;
        top: 15%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}