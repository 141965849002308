header {
    width: 100%;
}

header.header-about {
    background-color: #9ad3e5;
}

header.header-resources {
    background-color: #5ebcc4;
}

header.header-grants {
    background-color: #c1f7fc;
}

header.header-orrimap {
    background-color: #51C7DF;
}

#logo {
    float: left;
    margin-top: 20px;
    width: 330px;
    height: 106px;
}

#logo img {
    width: 330px;
    height: 106px;
}

#logo a {
    text-indent: -9999px;
}

#menu {
    float: right;
    margin-top: 60px;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    header .content {
        padding-bottom: 20px;
        height: 154px;
    }

    #logo img {
        width: auto;
        height: 114px;
        padding: 10px;
    }
}