.oss-platform-services .services .services-tabs .services-tabs-body .sibling-elements {
    width: calc(100% - 80px);
    height: auto;
    margin-left: 40px;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding-bottom: 50px;
    padding-top: 30px;
}

.sibling-element {
    width: 328px;
    box-shadow: 6px 5px 3px #f4f4f4;
    background-color: white;
    border-radius: 14px;
    align-self: flex-start;
}

.sibling-element-logo {
    height: 130px;
    background-color: #aadde3;
    border-radius: 14px;
    width: 100%;
}

.sibling-element-circle {
    width: 106px;
    height: 106px;
    background-color: white;
    margin-left: 111px;
    margin-right: 111px;
    margin-top: 12px;
    float: left;
    border-radius: 53px;
}

.sibling-element-circle img {
    width: 90px;
    border-radius: 53px;
    margin-top: 8px;
    margin-left: 8px;
}

.sibling-element-body {
    background-color: white;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    height: auto;
}

.sibling-element-title h3 {
    font-size: 19px !important;
    font-weight: 600;
    padding: 20px;
    padding-bottom: 0px;
}

.sibling-element-text {
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 15px !important;
}

.sibling-element-text a {
    font-weight: 600;
    color: #047d8a;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-slibing {
    padding: 0px !important;
}

.services-tabs-body-data .slibing-container {
    display: flex;
    width: 100%;
    margin: 30px;
    padding-top: 30px;
    padding: 40px;
}

.services-tabs-body-data .left {
    border-right: solid 1px #a8a8a8;
    width: 770px !important;
    padding-right: 130px;
}

.services-tabs-body-data .right {
    width: 340px;
}

.services-tabs-body-data .left .slibing-title {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.services-tabs-body-data .right .image {
    width: 140px;
    height: 140px;
    border-radius: 70px;
    border: solid 1px #a0f9ff;
    margin-left: 30px;
    background-color: white;
}

.services-tabs-body-data .right img {
    width: 130px;
    margin-left: 5px;
    margin-top: 5px;
    border-radius: 65px;
}

.services-tabs-body-data .right .duration-title,
.services-tabs-body-data .right .more-info-title {
    margin-left: 30px;
    font-size: 24px;
    color: #808080;
}

.services-tabs-body-data .right .duration-title {
    margin-top: 30px;
}

.services-tabs-body-data .right .more-info-title {
    margin-top: 20px;
}

.services-tabs-body-data .right .duration-text {
    color: #017581;
    font-size: 17px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: 5px;
}

.services-tabs-body-data .right .more-info-text {
    background-color: #ceeff2;
    margin-left: 30px;
    float: left;
    padding: 5px 10px;
    border-radius: 9px;
    margin-top: 15px;
}

.services-tabs-body-data .right .more-info-text a {
    color: #017581 !important;
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
}

.services-tabs-body-data .right .social {
    width: 100%;
    float: left;
    margin-left: 30px;
    margin-top: 20px;
}

.services-tabs-body-data .right .social .categories-button-circular {
    width: 30px !important;
}

.services-tabs-body-data .right .social a {
    width: 25px !important;
    height: 25px !important;
    display: block !important;
    background-color: #0198a6;
    float: left;
    margin-right: 8px;
    text-align: center;
    border-radius: 50%;
    color: white;
}