#conference-banner {
    background: url(../../images/conference/title_banner.png) no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0px;
}

#conference-banner .conference-title {
    padding: 9%;
}

#conference-banner .conference-title .conference-title-title {
    width: 50%;
    display: flex;
}

#conference-banner .conference-title .conference-title-title h1 {
    font-family: Raleway;
    font-size: 45px;
    font-weight: 800;
    line-height: 60px;
    text-align: left;
    color: #003A41;
}

#conference-banner .conference-title .conference-title-subtitle {
    padding-top: 2%;
    width: 40%;
}

#conference-banner .conference-title .conference-title-subtitle h3 {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 300;
    line-height: 33.5px;
    text-align: left;
    color: #003A41;
}

#conference-banner .conference-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
}

#conference-banner .conference-info .conference-title-card {
    background-color: var(--celeste, #08B0C0);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 300px;
    padding: 30px;
    padding-left: 50px;
}

#conference-banner .conference-info .conference-title-card .conference-title-card-subheader h3 {
    font-size: 17px;
    font-style: italic;
    font-weight: 500;
    line-height: 33.5px;
    text-align: left;
    color: #FFFFFF;
}

#conference-banner .conference-info .conference-title-card .conference-title-card-header h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 33.5px;
    text-align: left;
    color: #A0F9FF;
    text-transform: uppercase;
}

#about-container {
    margin-top: 20px;
}

#about-container .about-menu {
    border-radius: 20px;
    align-items: center;
    text-align: center;
    background-color: #003A41;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 700px;
    margin: 0 auto;
    margin-bottom: 70px;
}

#about-container .about-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    text-align: center;
}

#about-container .about-menu li {
    display: inline-block;
    margin: 0 60px;
}

#about-container .about-menu a {
    font-size: 16px;
    font-weight: 600;
    line-height: 24.77px;
    color: #FFFFFF;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
}

#about-container .about-menu a:hover {
    color: var(--celeste, #08B0C0);
}

#about-container .about-menu a:active {
    filter: brightness(90%);
}

#about-container .about-banner {
    background: url(../../images/conference/about_banner.svg) no-repeat;
    background-size: 45%;
    background-position: top 310px right;
}

#about-container .about-banner .about-banner-text {
    padding-top: 10px;
    padding-bottom: 50px;
    width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

#about-container .about-banner .about-banner-text .about-banner-text-title h1,
#photo-gallery-container .photo-gallery-text-title h1 {
    font-size: 29px;
    font-weight: 400;
    line-height: 35.5px;
    letter-spacing: 6px;
    text-align: left;
    color: #11B5C0;
    text-transform: uppercase;
}

#about-container .about-banner .about-banner-text .about-banner-text-subtitle h2 {
    font-size: 31px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #0F767E;
    width: 40%;
}

#about-container .about-banner .about-banner-text .about-banner-text-prebody h3 {
    padding-top: 1%;
    font-size: 21px;
    font-weight: 600;
    line-height: 28.5px;
    text-align: left;
    color: #05373D;
    width: 50%;
}

#about-container .about-banner .about-banner-text .about-banner-text-body {
    display: flex;
    margin-top: 10px;
}

#about-container .about-banner .about-banner-text .about-banner-text-body .left,
#about-container .about-banner .about-banner-text .about-banner-text-body .right {
    width: 620px;
}

#about-container .about-banner .about-banner-text .about-banner-text-body .left div,
#about-container .about-banner .about-banner-text .about-banner-text-body .right div {
    padding-top: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.5px;
    text-align: left;
    color: #5E6162;
    width: 85%;
}

#about-container .about-banner .about-banner-text #agenda-btn {
    background-color: #003A41;
    border-radius: 20px;
    border: 0;
    width: 200px;
    margin-top: 30px;
}

#about-container .about-banner .about-banner-text #agenda-btn h3 {
    text-transform: uppercase;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 6px;
}

#about-container .about-banner .about-banner-text #agenda-btn h3:hover {
    color: #11B5C0;
}

#about-container .about-banner .about-banner-text #agenda-btn h3:active {
    filter: brightness(90%);
}

#progamme-container .content .programme-title {
    text-align: center;
    align-items: center;
    padding-top: 100px;
}

#progamme-container .content .programme-title .programme-title-back h1 {
    font-size: 79px;
    font-weight: 300;
    line-height: 35.5px;
    letter-spacing: 6px;
    color: #11B5C0;
    text-transform: uppercase;
    filter: opacity(15%);
}

#progamme-container .content .programme-title .programme-title-front h2 {
    font-size: 29px;
    font-weight: 400;
    line-height: 35.5px;
    letter-spacing: 6px;
    color: #11B5C0;
    text-transform: uppercase;
}

#photo-gallery-container {
    width: 1240px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
}

#photo-gallery-container .photo-gallery-text-title {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

#photo-gallery-container .photo-gallery-text-title h1 {
    text-align: center;
}

#photo-gallery-container .photo-gallery-text-body {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: solid 10px #11b5c0;
}

#agenda-container {
    background-color: #D0ECF2;
    padding-bottom: 60px;
}

#agenda-container .content .agenda-title h1 {
    font-size: 31px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #0F767E;
    border-bottom: 2px solid #0F767E;
    margin-top: 40px;
}

#agenda-container .content .agenda-introduction {
    font-size: 20px;
    font-weight: 400;
    line-height: 28.5px;
    text-align: left;
    color: #5E6162;
    width: 87%;
    margin-top: 20px;
    width: 100%;
}

#agenda-container .content .agenda-back-img {
    position: absolute;
    right: calc(50% - 650px);
    margin-top: 50px;
}

#agenda-container .content .agenda-body {
    margin-top: 30px;
    position: relative;
}

#agenda-container .content .agenda-column {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
}

#agenda-container .content .agenda-column .agenda-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

#agenda-container .content .agenda-column .agenda-text img {
    padding-top: 50px;
    margin: 0 auto;
}

#agenda-container .nav-item {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    padding-left: 25px !important;
    padding-top: 25px !important;
    padding-bottom: 10px !important;
    padding-right: 25px !important;
}

#agenda-container .nav-item a {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

#agenda-container .nav-item:nth-child(1) {
    padding-left: 0px !important;
}

#agenda-container .nav-link.active {
    background-color: white !important;
}

#agenda-container .nav-link h2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #11B5C0 !important;
    text-transform: uppercase;
    padding-top: 10px;
    padding-left: 20px !important;
}

#agenda-container .nav-link h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35.5px;
    text-align: left;
    color: #05373D !important;
    text-transform: uppercase;
    padding-left: 20px !important;
    padding-right: 25px !important;
}

#agenda-container .nav-link.active {
    background-color: white !important;
}

#agenda-container .nav-link.active h2 {
    color: #11B5C0 !important;
    padding-left: 20px !important;
}

#agenda-container .nav-link.active h1 {
    color: #05373D !important;
}

#agenda-container .agenda-schedule-body {
    margin-top: -6px;
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

#agenda-container .agenda-schedule-body>ul {
    list-style: none;
    margin: 0;
    padding-left: 20px;
}

#agenda-container .agenda-schedule-body>ul>li {
    border-bottom: 1px solid #D4D4D4;
    width: calc(100% - 20px);
    display: grid;
    grid-template-columns: 150px calc(100% - 200px);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

#agenda-container .agenda-schedule-body>ul>li:last-child {
    border-bottom: 0 solid;
}

#agenda-container .agenda-schedule-body-list-activity-hour {
    grid-column: 1;
}

#agenda-container .agenda-schedule-body-list-activity-title,
#agenda-container .agenda-schedule-body-list-activity-subtitle {
    grid-column: 2;
}

#agenda-container .agenda-schedule-body-list-activity-hour h4 {
    font-size: 21px;
    font-weight: 700;
    text-align: left;
    color: #0F767E;
}

#agenda-container .agenda-schedule-body-list-activity-title h3 {
    font-size: 21px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0px;
    color: #05373D;
}

#agenda-container .agenda-schedule-body-list-activity-subtitle h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28.5px;
    text-align: left;
    color: #0198A6;
    margin-bottom: 0px;
}

#agenda-container .agenda-schedule-body-list-activity-subtitle p {
    font-size: 16px;
}

#agenda-container .agenda-schedule-body-list-activity-subtitle ul {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
}

#agenda-container .agenda-schedule-body-list-activity-subtitle ul li {
    list-style: disc;
    font-size: 16px !important;
    grid-template-columns: 150px calc(100% - 150px) !important;
    width: 100% !important;
}

.agenda-schedule-body-inner li {
    width: 100% !important;
}

#agenda-container .pararellTitle {
    background-color: #beeaf0;
    color: black;
    width: calc(100% - 40px);
    margin-left: 20px;
    padding-left: 20px;
    font-size: 19px;
    font-weight: 600;
}

#agenda-container #pararell-tabs {
    margin-left: 20px;
    padding-left: 20px;
    width: calc(100% - 40px);
}

#agenda-container #pararell-tabs .nav-item {
    padding-right: 5px !important;
    padding-left: 0px !important;
}

#agenda-container #pararell-tabs .nav-link {
    color: #0198a6 !important;
    border: solid 1px #0198a6 !important;
    border-radius: 20px !important;
    height: 45px !important;
    font-size: 18px;
    line-height: 26px;
}

#agenda-container #pararell-tabs .nav-link.active {
    background-color: #0198a6 !important;
    color: white !important;
}

#agenda-container .agenda-schedule-body-inner {
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
}

#submission-container .content .speakers-section {
    padding-top: 50px;
}

#submission-container .content .speakers-section .speakers-title h1 {
    font-size: 31px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #0F767E;
    border-bottom: 2px solid #0F767E;
    margin-top: 40px;
}

#agenda-container .agenda-schedule-body-inner {
    width: calc(100% - 40px) !important;
    margin-left: 20px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    background-color: #f4f4f4;
}

#submission-container .content .speakers-section .speakers-body {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    position: relative;
}

#submission-container .content .speakers-section .speakers-column {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
}

#submission-container .content .speakers-section .speakers-card {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    position: relative;
}

#submission-container .content .speakers-section #speakers-card-row-1 {
    min-height: 306px;
}

#submission-container .content .speakers-section #speakers-card-row-2 {
    min-height: 365px;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-column {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-column:first-child {
    flex: 0 0 35%;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-column:last-child {
    flex: 0 0 65%;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-img {
    min-width: 240px;
}

.speakers-card-img img {
    max-width: 100%;
    height: 260px;
    display: block;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-title {
    display: flex;
    align-items: center;
    justify-content: align-items;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-title h2 {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.5px;
    text-align: left;
    color: #05373D;
    margin: 0;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-title .btn-social-media {
    border-radius: 50%;
    border: none;
    background-color: #11B5C0;
    color: #ffffff;
    border: 0;
    margin-right: 5%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
    font-size: 22px;
    margin-left: 20px;

}

#submission-container .content .speakers-section .speakers-card .speakers-card-title .btn-social-media:hover {
    color: #000000;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-title .btn-social-media:active {
    filter: brightness(90%);
}

#submission-container .content .speakers-section .speakers-card .speakers-card-body {
    width: 90%;
    padding-top: 10px;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-body h4 {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.5px;
    text-align: left;
    color: #5E6162;
}

#submission-container .content .speakers-section .speakers-card .speakers-card-body h4 a {
    color: #11B5C0;
}

#submission-container .content .submission-card {
    margin-top: 100px;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 5%;
}

#submission-container .content .submission-card .submission-body {
    display: flex;
    justify-content: space-between;
}

#submission-container .content .submission-card .submission-body .submission-body-column:first-child {
    flex-basis: 55%;
}

#submission-container .content .submission-card .submission-body .submission-body-column:last-child {
    flex-basis: 45%;
}

#submission-container .content .submission-card .submission-body .submission-body-column {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-text .submission-body-text-title h1 {
    font-family: Raleway;
    font-size: 29px;
    font-weight: 400;
    line-height: 35.5px;
    letter-spacing: 6px;
    text-align: left;
    color: #11B5C0;
    text-transform: uppercase;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-text .submission-body-text-subtitle h2 {
    font-family: Raleway;
    font-size: 31px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #0F767E;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-text .submission-body-text-introduction h3 {
    font-family: Raleway;
    font-size: 21px;
    font-weight: 600;
    line-height: 28.5px;
    text-align: left;
    color: #05373D;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-text .submission-body-text-body h4 {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.5px;
    text-align: left;
    color: #5E6162;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates {
    background-color: #F9F9F9;
    border-radius: 15px;
    padding: 10%;
    margin-top: 100px;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates .submission-body-dates-title h3 {
    font-family: Raleway;
    font-size: 21px;
    font-weight: 600;
    line-height: 28.5px;
    text-align: left;
    color: #05373D;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates .submission-body-dates-list ul {
    margin: 0;
    padding-left: 0px;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates .submission-body-dates-list li {
    border-bottom: 2px solid #D4D4D4;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates .submission-body-dates-list li:last-child {
    border-bottom: 0;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates .submission-body-dates-list li h4 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.5px;
    text-align: left;
    color: #05373D;
    width: 53%;
}

#submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates .submission-body-dates-list li h3 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.5px;
    text-align: left;
    color: #0F767E;
    width: 32%;
}

#submission-container .content .submission-card .submission-button {
    margin-top: 50px;
    text-align: center;
}

#submission-container .content .submission-card .submission-button #submission-btn {
    background-color: #003A41;
    width: 400px;
    border: 0;
    border-radius: 30px;
}

#submission-container .content .submission-card .submission-button #submission-btn h3 {
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    padding-top: 5px;
}

#submission-container .content .submission-card .submission-button #submission-btn h3:hover {
    color: #11B5C0;
}

#submission-container .content .submission-card .submission-button #submission-btn h3:active {
    filter: brightness(90%);
}

@media only screen and (max-width: 768px) {
    #agenda-container .agenda-back-img {
        display: none;
    }

    #about-redirection {
        background: none !important;
    }

    #about-container .about-banner .about-banner-text .about-banner-text-body div {
        padding-top: 20px;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #5E6162;
        width: 90%;
    }

    #agenda-redirection {
        width: 95% !important;
        padding-left: 5% !important;
    }

    #agenda-container .agenda-text {
        font-size: 16px;
    }

    #submission-container {
        width: 95% !important;
        padding-left: 5% !important;
    }

    #submission-container .content .speakers-section .speakers-body {
        display: block;
        width: 100% !important;
    }

    #submission-container .content .speakers-section .speakers-card {
        width: 100% !important;
        margin-top: 0px !important;
        display: block;
    }

    .speakers-card {
        width: 100% !important;
        min-width: 100% !important;
        margin-top: 0px !important;
    }

    .speakers-card-column {
        padding: 0px !important;
    }

    .speakers-card-img {
        width: 100% !important;
        min-width: 100% !important;
    }

    .speakers-card-title h2,
    .speakers-card-title a {
        margin-top: 20px !important;
    }

    .speakers-card-body,
    .speakers-card-body h4 {
        font-size: 16px !important;
        width: 100% !important;
        margin-bottom: 30px !important;
    }

    #agenda-container .agenda-body>.row>.col>.nav>.nav-item {
        width: 50% !important;
        padding: 0% !important;
    }

    #agenda-container .agenda-body>.row>.col>.nav>.nav-item>.nav-link {
        width: 100% !important;
        padding: 0% !important;
    }

    #agenda-container .agenda-body>.row>.col>.nav>.nav-item>.nav-link h2 {
        font-size: 15px;
        margin-bottom: 0px;
    }

    #agenda-container .agenda-body>.row>.col>.nav>.nav-item>.nav-link h1 {
        font-size: 15px;
    }

    #agenda-container .agenda-schedule-body>ul>li {
        display: block !important;
    }

    .submission-card {
        width: 100% !important;
    }

    #submission-container .content .submission-card .submission-body {
        display: block;
        width: 100% !important;
    }

    #submission-container .content .submission-card .submission-body .submission-body-column .submission-body-dates {
        margin-top: 50px !important;
    }
}