.content-2 {
    max-width: 1295px;
    margin: 0 auto;
    padding-bottom: 70px;
}

.services-tabs .nav-link.active {
    background-color: #F8F8F8 !important;
    border-radius: 15px 15px 0px 0px;
    border: none;
    border-bottom: solid 1px #f8f8f8 !important;
}

.oss-platform-services #title {
    background-color: #EFF4F5 !important;
}

.oss-platform-services #title .banner {
    background-color: #a1dce2 !important;
    border-radius: 15px 15px 15px 15px;
    height: 185px;
    gap: 0px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    background: url(../../images/oss-platform/title_background.svg) no-repeat;
    background-size: 500px;
    background-position: -150px -110px;
    margin-bottom: 50px;
    justify-content: space-between;
}

.oss-platform-services #title .banner .banner-text {
    padding-left: 60px;
}

.oss-platform-services #title .banner .banner-logo img {
    padding-right: 80px;
}

.oss-platform-services #title .banner .banner-title h1 {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 35.5px;
    text-align: left;
    color: #0F767E;
    text-transform: uppercase;
}

.oss-platform-services #title .banner .banner-subtitle h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 5px;
    text-align: left;
    color: #003A41;
    text-transform: uppercase;
}

.oss-platform-services .introduction .content {
    margin: 0 auto;
    max-width: 60%;
    padding-left: 50px;
}

.oss-platform-services .introduction .introduction-body h5 {
    padding-top: 110px;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: #05373D;
}

.oss-platform-services .services {
    margin-top: 40px;
    background-color: #e7eaeb;
    background-image: url(../../images/oss-platform/services_background.svg);
    background-repeat: no-repeat;
    background-size: 500px;
    background-position: 1015px -20px;
}

.oss-platform-services .services .searcher {
    width: 400px;
    margin: 0 auto;
    display: inline-block !important;
    margin-bottom: 35px;
    margin-top: 25px;
}

.input-wrapper {
    position: relative;
}

#search-input {
    padding-right: 40px;
    margin: 0 auto;
    border-radius: 30px;
    border: 1px solid;
    border-color: #017581;
}

input[type="search"]::-webkit-search-cancel-button {
    padding-right: 2%;
    cursor: pointer;
    padding-bottom: 0.8%;
}

input[type="search"]::-ms-clear {
    padding-right: 2%;
    cursor: pointer;
    padding-bottom: 0.8%;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    padding-right: 2%;
    cursor: pointer;
    padding-bottom: 0.8%;
}

.search-icon {
    color: #017581;
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;
}

.oss-platform-services .services .tab_title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.oss-platform-services .services .tab_title h1 {
    padding-top: 9px;
    font-size: 17.94px;
    font-weight: 600;
    line-height: 27.68px;
    text-align: left;
    color: #017581;
}

.oss-platform-services .services .tab_title h1:hover {
    color: #23e9ff;
}

.oss-platform-services .services .tab_title h1:active {
    filter: brightness(90%);
}

.oss-platform-services .services .services-tabs .nav {
    border: 0;
    margin: 0 auto;
}

.oss-platform-services .services .services-tabs .services-tabs-body {
    background-color: #F8F8F8;
    border-radius: 15px;
    position: relative;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data,
.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees {
    padding: 40px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-title h1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #003A41;
    margin-bottom: 30px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    justify-content: space-between;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 1090px;
    flex-direction: 'row';
    justify-content: space-around;
    margin-left: 60px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-cards-info {
    width: 100%;
    flex-basis: 535px;
    height: 197px;
    border-radius: 18px;
    background-color: #138794;
    position: relative;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-cards-info:before {
    background: url(../../images/oss-platform/tab_card_background.svg) no-repeat;
    background-position: right 0px top -20px;
    width: 100%;
    height: 197px;
    background-size: 260px 260px;
    position: absolute;
    z-index: 1;
    content: "";
    opacity: 1;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-tools-introduction {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-tools-introduction h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: #05373D;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-tools-introduction button {
    padding: 12px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    background-color: #25B8C7;
    border-radius: 30px;
    border: 0;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-tools-introduction button:hover {
    color: #000000;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-tools-introduction button:active {
    filter: brightness(90%);
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-column .services-tabs-column-interests {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-1 {
    background-color: #DBEEF1;
    border: 1px solid;
    border-color: #047D8A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 270px;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    height: 80px;
    cursor: pointer;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-1.selected {
    background-color: #A0F9FF;
    border: 0;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-2 {
    background: url(../../images/oss-platform/tab_card_background_2.svg) no-repeat;
    background-size: contain;
    background-position: right;
    background-color: #2798A4;
    border: 1px solid;
    border-color: #047D8A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 270px;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    height: 80px;
    cursor: pointer;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-2.selected {
    background-color: #A0F9FF;
    border: 0;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-2.selected h4 {
    color: #047D8A;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-1 h4 {
    font-size: 19px;
    font-weight: 600;
    text-align: left;
    color: #047D8A;
    padding-top: 10px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-tools-card-2 h4 {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 600;
    text-align: left;
    color: #FFFFFF;
    padding-top: 5px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-column h3 {
    font-size: 25px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #003A41;
    margin: 0;
    padding-bottom: 30px;
}

#services-tabs-body-data-cards-2 {
    margin-left: -43px;
}

#services-tabs-body-data-cards-3 {
    text-align: center;
    margin: 0 auto;
    width: 35%;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-columns-container .services-tabs-column .services-tabs-column-works .services-tabs-tools-card-1 button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 35px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info h3 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: #5A6D70;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card {
    background: url(../../images/oss-platform/tab_card_background.svg) no-repeat;
    background-size: 180px;
    background-position: right;
    background-color: #2798A4;
    border-radius: 15px;
    height: 100px;
    width: 390px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card a,
.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card button {
    font-size: 23px;
    font-weight: 600;
    line-height: 57px;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-top: 23px;
    padding-right: 30px;
    float: left;
    border: none;
    border-radius: 15px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card a.two-lines {
    line-height: 30px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card a:hover {
    color: #23e9ff;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card a:active {
    filter: brightness(90%);
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card a span {
    width: calc(100% - 55px);
    display: inline-block;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-body-data-cards .services-tabs-cards-info .services-tabs-card a img {
    float: right;
    width: 55px;
}

.oss-platform-services .services .services-tabs .services-tabs-body-links {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.oss-platform-services .services .services-tabs .services-tabs-body-links .services-tabs-link-card {
    background-color: #d8dedf;
    border-radius: 15px;
    padding: 25px;
    width: 450px;
}

.oss-platform-services .services .services-tabs .services-tabs-body-links .services-tabs-link-card h1 {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    color: #003A41;
}

.oss-platform-services .services .services-tabs .services-tabs-body-links .services-tabs-link-card a {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #20b4c5;
    cursor: pointer;
}

.oss-platform-services .services .services-tabs .services-tabs-body-links .services-tabs-link-card a:hover {
    color: #26d2e5;
}

.oss-platform-services .services .services-tabs .services-tabs-body-links .services-tabs-link-card a:active {
    filter: brightness(90%);
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data .services-tabs-tools-introduction #results-btn:disabled {
    background-color: #7ac1c9;
    color: #FFFFFF;
    opacity: 0.6;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-card {
    position: relative;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-card .title {
    color: white;
    font-size: 25px;
    font-weight: 600;
    margin-left: 25px;
    margin-top: 20px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-card .opencall {
    color: #A0F9FF;
    font-size: 25px;
    font-weight: 600;
    margin-left: 25px;
    margin-top: 10px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-card .callDate {
    color: white;
    font-size: 24px;
    font-weight: 400;
    margin-left: 25px;
    margin-top: 10px;
    width: calc(100% - 50px);
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards .services-tabs-card .callDate svg {
    float: right;
    font-size: 40px;
    margin-top: -4px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .services-tabs-body-data-grantees .services-tabs-body-data-cards a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 5;
}

.oss-platform-services .services .services-tabs .services-tabs-body .breadcrumb {
    font-size: 17px;
    color: #003a41;
    margin-top: 20px;
    margin-left: 40px;
    display: block !important;
    float: left;
    width: 100%;
}

.oss-platform-services .services .services-tabs .services-tabs-body .breadcrumb span {
    color: #8b8b8b;
}

.oss-platform-services .services .services-tabs .services-tabs-body .back {
    color: #017581;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 40px;
    display: block !important;
    float: left;
    cursor: pointer;
    text-decoration: none;
}

.oss-platform-services .services .services-tabs .services-tabs-body .back svg {
    margin-right: 5px;
    margin-top: -4px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    color: #05373D;
    margin-bottom: 30px;
    width: 400px;
    margin: 0 auto;
    text-align: center;
    padding-top: 150px !important;
    padding-bottom: 50px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-background-left {
    background: url(../../images/oss-platform/grantee_2.svg) no-repeat;
    width: 90px;
    height: 90px;
    position: absolute;
    background-size: contain;
    right: calc(50% + 250px);
    top: 125px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-background {
    background: url(../../images/oss-platform/grantee.svg) no-repeat;
    width: 200px;
    height: 200px;
    position: absolute;
    background-size: contain;
    right: calc(50% - 100px);
    top: 75px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-background-right {
    background: url(../../images/oss-platform/grantee_1.svg) no-repeat;
    width: 90px;
    height: 90px;
    position: absolute;
    background-size: contain;
    left: calc(50% + 250px);
    top: 125px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-subtitle {
    color: #08b0c0;
    font-size: 29px;
    font-weight: 600;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    float: left;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-title {
    color: black;
    font-size: 25px;
    font-weight: 700;
    padding-top: 10px;
    width: 100%;
    text-align: center;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-text {
    font-size: 20px;
    color: #05373D;
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-top: 40px;
    font-weight: 500;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-text a {
    color: #05373D;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-text a:hover {
    color: #08b0c0;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects-text {
    font-size: 18px;
    color: #017581;
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-top: 30px;
    font-weight: 500;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects {
    display: flex;
    gap: 40px;
    width: 1140px;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 70px;
    padding-bottom: 50px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects .grantee-opencall-projects-card {
    width: 553px;
    height: 360px;
    background-color: white;
    border-radius: 15px;
    flex: 1 0 40%;
    box-shadow: 6px 5px 3px #f4f4f4;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects .grantee-opencall-projects-card .grantee-opencall-projects-card-title {
    font-size: 25px;
    font-weight: 600;
    color: #003a41;
    padding-top: 30px;
    padding-left: 30px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects .grantee-opencall-projects-card .grantee-opencall-projects-card-subtitle {
    color: #08b0c0;
    font-size: 19px;
    padding-top: 0px;
    font-weight: 600;
    padding-left: 30px;
    max-width: 95%;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects .grantee-opencall-projects-card .grantee-opencall-projects-card-text {
    font-size: 17px;
    color: #212121;
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects .grantee-opencall-projects-card .grantee-opencall-projects-card-text a {
    color: #25b8c7 !important;
}

.oss-platform-services .services .services-tabs .services-tabs-body .grantee-opencall-projects .grantee-opencall-projects-card .grantee-opencall-projects-card-link {
    background-color: #25b8c7;
    padding: 7px 30px 5px 30px;
    text-transform: uppercase;
    float: left;
    margin-left: 30px;
    margin-top: 12px;
    color: white;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 600;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-title {
    color: black;
    font-size: 25px;
    padding-top: 10px;
    width: 100%;
    text-align: center;
    float: left;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-selections {
    width: calc(100% - 80px);
    margin-left: 40px;
    display: inline-block;
    padding-top: 10px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-selections .tools-selections-element {
    border-radius: 9px;
    background-color: #0198a6;
    color: white;
    font-size: 12px;
    float: left;
    padding: 5px 10px 3px 10px;
    margin-right: 8px;
    margin-top: 10px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-selections .tools-selections-element svg {
    margin-left: 15px;
    font-size: 18px;
    margin-top: -2px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    width: 1150px;
    flex-direction: 'row';
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: 36px;
    padding-bottom: 50px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card {
    width: 100%;
    flex-basis: 553px;
    height: 152px;
    border-radius: 15px;
    background-color: white;
    position: relative;
    box-shadow: 6px 5px 3px #f4f4f4;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card .tools-results-card-image {
    width: 90px;
    height: 90px;
    float: left;
    margin-left: 30px;
    margin-top: 30px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card .tools-results-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 45px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card .tools-results-card-title {
    font-size: 19px;
    float: left;
    margin-left: 30px;
    margin-top: 25px;
    font-weight: 600;
    width: calc(100% - 200px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card-no-image .tools-results-card-title {
    width: calc(100% - 60px);
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card .tools-results-card-text {
    font-size: 19px;
    float: left;
    margin-left: 30px;
    margin-top: 5px;
    width: calc(100% - 200px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card-no-image .tools-results-card-text {
    width: calc(100% - 60px);
}

.oss-platform-services .services .services-tabs .services-tabs-body .tools-results .tools-results-card a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 5;
}

.services-tabs-body-data .tool-container {
    display: flex;
    width: 100%;
    margin: 30px;
    padding-top: 30px;
}

.services-tabs-body-data .left {
    border-right: solid 1px #a8a8a8;
    width: 700px;
}

.services-tabs-body-data .right {
    width: 440px;
}

.services-tabs-body-data .left .tool-title,
.services-tabs-body-data .title {
    color: #003a41;
    font-size: 32px;
    font-weight: 600;
    width: 100%;
}

.services-tabs-body-data .left .tool-image {
    max-width: calc(100% - 60px);
    margin-top: 20px;
    margin-bottom: 20px;
}

.services-tabs-body-data .left .tool-image img {
    width: 100%;
}

.services-tabs-body-data .left .tool-summary {
    font-size: 20px;
    color: #212121;
    font-weight: 300;
    margin-top: 10px;
    padding-right: 50px;
}

.services-tabs-body-data .left .tool-results {
    margin-top: 50px;
}

.services-tabs-body-data .left .tool-results .title {
    color: #0f767e;
    font-size: 29px;
    font-weight: 600;
    margin-bottom: 20px;
}

.services-tabs-body-data .left .tool-results p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 5px;
}

.services-tabs-body-data .left .tool-results p span {
    color: #003a41;
    font-weight: 600;
}

.services-tabs-body-data .left .tool-results p a {
    color: #003a41;
}

.services-tabs-body-data .right .tool-language .title,
.services-tabs-body-data .right .tool-keywords .title {
    color: #808080;
    font-size: 26px;
    padding-left: 50px;
}

.services-tabs-body-data .right .tool-language p,
.services-tabs-body-data .right .tool-keywords p {
    padding-left: 50px;
    margin-top: 20px;
}

.services-tabs-body-data .right .tool-language p span,
.services-tabs-body-data .right .tool-keywords p span {
    font-size: 14px;
    color: #017581;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    background-color: #ceeff2;
    padding: 5px 10px;
    border-radius: 15px;
}

.services-tabs-body-data .right .tool-funded {
    padding-left: 50px;
    margin-top: 20px;
}

.services-tabs-body-data .right .tool-funded img {
    width: calc(100% - 100px);
}

.services-tabs-body-data .subtitle {
    font-size: 24px;
    color: #017581;
    font-weight: 600;
    margin-bottom: 20px;
}

.services-tabs-body .pagination .page-link {
    background: none !important;
    border: none;
    color: #7d7d7d;
}

.services-tabs-body .pagination .active .page-link {
    color: #08b0c0;
    font-weight: 600;
}

.services-tabs-body .pagination {
    justify-content: center;
}

.services-tabs-body .pagination .break2 {
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
}

.services-tabs-body .pagination .page-link.previous,
.services-tabs-body .pagination .page-link.next {
    color: #08b0c0;
    font-weight: 600;
}

/* ----------------------- FORM ----------------------- */

.input-with-icon {
    position: relative;
    display: inline-block;
    width: 100%;
}

.input-with-icon .form-control {
    padding-right: 2.5rem;
}

.input-with-icon .form-control::placeholder {
    text-align: left;
}

#publication_year {
    max-width: 250px;
}

.calendar-icon {
    position: absolute;
    top: 35%;
    left: 12.5%;
    color: #555;
}

#remove-image-btn {
    margin-left: 30px;
}

#form-btn {
    background-color: #08B0C0;
    border: 0;
}

#form-btn:hover {
    color: #000000;
}

#form-btn:active {
    background-color: #0a7882;
}

#own-orri-tool-btn {
    background-color: #017581;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
}

.oss-platform-services .services .services-tabs .services-tabs-body .sibling-title {
    color: #08b0c0;
    font-size: 25px;
    padding-top: 40px;
    width: 100%;
    text-align: center;
    float: left;
    font-size: 29px;
    font-weight: 600;
    padding-bottom: 10px;
}

/* .alert {
    max-height: 20px;
} */

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .oss-platform-services #title .banner {
        background-color: #a1dce2 !important;
        border-radius: 15px 15px 15px 15px;
        height: 185px;
        gap: 0px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        background: url(../../images/oss-platform/title_background.svg) no-repeat;
        background-size: 500px;
        background-position: -150px -110px;
        margin-bottom: 50px;
        justify-content: space-between;
    }

    .oss-platform-services #title .banner .banner-text {
        padding-left: 20px;
    }

    .oss-platform-services #title .banner .banner-logo img {
        padding-right: 80px;
        width: 500px;
        height: 500px;
    }

}