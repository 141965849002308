/* --- ORRI MAP PAGE --- */

#orri-map-banner {
    background-color: #9ad3e5;
}

.orri-map-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.orri-map-txt {
    grid-column: 1;
}

.orri-title {
    background-color: #51c7df !important;
}

.orri-title .title-subtitle {
    color: #ffffff;
    text-align: left;
}

.orri-title .title-title {
    color: #05373D;
    text-align: left;
    text-transform: uppercase;
    width: 80%;
}

.orri-map-body .view-orri-map {
    background-color: #0F767E;
    padding: 8px 30px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 200px;
    text-transform: uppercase;
    text-decoration: none;
}

.orri-map-body .view-orri-map:hover {
    color: #000000;
}

.orri-map-body .view-orri-map:active {
    filter: brightness(90%);
}

#orri-map-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 11px;
}

.orri-map-btn {
    grid-column: 2;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.orri-map-button {
    background-color: #0f767e;
    color: #ffffff;
    border-radius: 20px;
    padding: 8px 30px 8px 30px;
    font: 500 16px "Inter-SemiBold", sans-serif;
    cursor: pointer;
}

.orri-map-button:hover {
    color: #000000;
}

.orri-map-button:active {
    filter: brightness(90%)
}

#orri-map-explanation {
    background: url(../../images/home/home_background_2.svg) no-repeat;
    background-color: #ceeaf18f;
    background-position: right;
    background-size: contain;
    overflow: hidden;
}

.orri-map-body {
    margin-top: 8%;
    max-width: 57%;
    margin-bottom: 10%;
}

.orri-map-body-title {
    color: #0F767E;
    text-align: left;
    font-size: 26px;
    font-weight: 600;
    line-height: 33.5px;
}

.orri-map-body-subtitle {
    color: #05373D;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 28.5px;
    padding-bottom: 20px;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .orri-title .content .title-title {
        line-height: 24px;
        margin-top: 10px;
        width: 330px !important;
    }

    #orri-map-explanation #breadcrumb {
        margin-bottom: -320px;
    }

    #orri-map-explanation {
        background: url(../../images/home/home_background_2.svg) no-repeat;
        background-color: #ceeaf18f;
        background-position: bottom right;
        background-size: 95%;
        overflow: hidden;
        height: 940px;
    }

    #orri-map-explanation .content .orri-map-body .orri-map-body-title {
        text-align: center;
        width: 325px;
        margin-left: 15px;
    }

    #orri-map-explanation .content .orri-map-body .orri-map-body-subtitle {
        text-align: center;
        width: 325px;
        margin-left: 15px;
    }

    .view-orri-map-btn {
        width: 400px;
    }

    .orri-map-body .view-orri-map {
        margin-left: 80px;
        background-color: #0F767E;
        padding: 10px 30px;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        border-radius: 20px;
        text-transform: uppercase;
        text-decoration: none;
    }
    
    .orri-map-body .view-orri-map:hover {
        color: #000000;
    }
    
    .orri-map-body .view-orri-map:active {
        filter: brightness(90%);
    }    

}