.custom-nav {
    display: flex;
    justify-content: space-around;
    margin-right: -20px;
}

.mobile-nav {
    display: none;
}

.custom-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.custom-nav li {
    margin-right: 20px;
}

.custom-nav ul li .nav-link {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #00272C;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.774px;
    text-transform: uppercase;
    cursor: pointer;
}

.custom-nav ul li .nav-link:hover {
    color: #30908b;
}

.custom-nav ul li .nav-link:hover svg {
    fill: #30908b;
}

.custom-nav svg {
    font-size: 17px;
    margin-bottom: 2px;
    color: white;
}

.custom-nav ul ul {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: solid 3px #0198A6;
    color: #05373D;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 47px;
    z-index: 1000;
    padding-left: 2%;
}

.custom-nav ul ul li {
    border-bottom: solid 1px #E1E1E1;
}

.custom-nav ul ul li:last-child {
    border-bottom: none;
}

.custom-nav ul li:hover>ul {
    display: flex;
    flex-direction: column;
}

.nav-link-contact {
    display: flex;
    padding: 9.009px 33.783px;
    justify-content: center;
    align-items: center;
    border-radius: 225.218px;
    background: #FFFFFF;
    color: #05373d;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;
    z-index: 5;
}

.nav-link-contact:hover {
    color: #30908b;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .custom-nav {
        display: none;
    }

    .mobile-nav {
        display: flex;
        align-items: center;
        position: relative;
    }

    .mobile-menu-icon {
        cursor: pointer;
        font-size: 24px;
        margin-right: 25px;
        margin-top: -280px;
        color: #00272C;
        font-size: 40px;
    }

    .mobile-menu-icon:hover {
        color: #3db7b1;
    }

    .mobile-menu-icon:active {
        color: #226361;
    }

    .mobile-nav-menu {
        display: none;
        background-color: #ffffff;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding-top: 10px;
        width: 210px;
        text-align: left;
        z-index: 5000;
        transition: width 0.3s ease, opacity 0.3s ease;
        padding-left: 0px;
        justify-content: space-between;
        overflow: hidden;
        margin-top: -130px;
        opacity: 95%;
        position: fixed;
        top: 160px;
        right: 0;
    }

    .mobile-nav-menu.open {
        display: block;
    }

    .mobile-nav-link {
        display: block;
        padding: 10px;
        text-decoration: none;
        color: #00272C;
        font-family: Raleway;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 24.774px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .mobile-nav-link:hover {
        color: #3db7b1;
    }

    .mobile-nav-link:active {
        color: #226361;
    }

    .mobile-ul-menu {
        list-style: none;
    }

    #mobile-ul-close {
        text-align: right;
        margin-right: 10px;
        font-size: 30px;
        color: #00272C;
        cursor: pointer;
    }

    #mobile-ul-close:hover {
        color: #3db7b1;
    }

    #mobile-ul-close:active {
        color: #226361;
    }

    .mobile-ul-menu li ul {
        list-style: none;
    }

    .nav-breaker {
        width: 82%;
        height: 1px;
        background-color: #e0e0e0;
        margin-left: 10px;
    }
}