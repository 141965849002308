/* --- HOME PAGE --- */

/* - HEADER - */

.header-home {
    background-color: #9ad3e5;
}

#home-banner {
    background-color: #9ad3e5;
}

.home-header {
    color: #05373d;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 13px;
    position: relative;
}

#home-title {
    margin-top: 100px;
    font-size: 60px;
}

.home-subheader {
    color: #0d6d75;
    text-align: left;
    position: relative;
    max-width: 77%;
    margin-top: -5%;
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    margin-top: 2%;
}

.home-header-button {
    padding-bottom: 262px;
    width: 100%;
}

.home-header-btn {
    background: #003A41;
    color: #ffffff;
    border-radius: 200px;
    padding: 8px 30px 8px 30px;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    position: relative;
    cursor: pointer;
    border: none;
    text-decoration-line: none;
    display: inline;
    margin-right: 10px;
}

.home-header-btn.know{
    background-color: white;
    text-transform: uppercase;
    color: #003A41;
}

.home-header-btn:hover {
    color: #5fd1f4;
}

.home-header-btn:active {
    filter: brightness(90%)
}

.home-header-logo {
    margin-bottom: 0%;
    text-align: center right;
    margin-right: 0%;
    z-index: 100;
}

.home-header-logo img {
    max-width: 100%;
    height: auto;
}

.home-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.home-txt {
    grid-column: 1;
}

.home-img {
    grid-column: 2;
}

/* - BODY - */

#home-body {
    background-color: #EFF4F5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-body-text {
    padding-top: 100px;
    text-align: center;
}

.home-body-title {
    color: #0f767e;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.5px;
    width: 80%;
    margin-left: 10%;
}

.home-body-subtitle {
    color: #5e6162;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.5px;
    padding-bottom: 70px;
    width: 80%;
    margin-left: 10%;
}

/* - CARDS - */

.card-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    margin-top: 60px;
}

.card {
    background-color: #eff4f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 32%;
}

.card-image {
    width: 80px;
    height: 80px;
    background-color: #dbe0e1;
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 40px;
}

.card img {
    height: 60px;
    padding-top: 15px;
}

.card-content {
    padding: 40px;
    padding-top: 0px;
}

.card-title {
    text-align: left;
}

#home-cards-title {
    color: #0F767E;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.148px;
    margin-bottom: 0px;
}

.card-description {
    color: #5E6162;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 0px;
}

#home-cards-text {
    padding-top: 16px;
    text-align: left;
}

.card-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* - BUTTON - */

#home-body-button {
    margin-top: 3%;
    margin-bottom: 7%;
}

.home-body-btn {
    margin-top: 3%;
    background: #25b8c7;
    border-radius: 200px;
    padding: 8px 30px 8px 30px;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border: 0;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 100px;
    text-decoration-line: none;
}

.home-body-btn:hover {
    color: #000000;
}

.home-body-btn:active {
    filter: brightness(90%)
}

/* - PARTNERS - */

#partners-banner {
    background: url(../../images/home/home_background_2.svg) no-repeat;
    background-color: #05373df1;
    background-position: right;
    background-size: contain;
    overflow: hidden;
}

.partners-text {
    margin-top: 7%;
    max-width: 57%;
}

.partners-title {
    color: #ffffff;
    text-align: left;
    font-size: 22px;
}

.partners-subtitle {
    color: #51c7df;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
}

.partners-button {
    margin-top: 20;
    margin-bottom: 100px;
    width: 30%;
}

.partners-btn {
    background: #25b8c7;
    border-radius: 200px;
    padding: 8px 30px 8px 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

#partners-btn-text:hover {
    color: #00515b;
}

.partners-btn:active {
    filter: brightness(90%)
}

#partners-btn-text {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    border: none;
    outline: none;
    text-decoration-line: none;
}

/* - CONFERENCE - */

#home-conference-banner {
    background: url(../../images/conference/title_banner.png) no-repeat;
    background-position: center;
    background-size: cover;
}

#home-conference-banner .conference-title {
    padding-top: 3%;
}

#home-conference-banner .conference-title .conference-title-text {
    padding-left: 9%;
}

#home-conference-banner .conference-title .conference-title-banner {
    background-color: #08B0C0;
    max-width: 460px;
}

#home-conference-banner .conference-title .conference-title-banner h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 33.5px;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;
    padding: 3%;
}

#home-conference-banner .conference-title .conference-title-button #conference-title-btn {
    background-color: #08B0C0;
    border-radius: 20px;
    border: 0;
    margin-bottom: 150px;
}

#home-conference-banner .conference-title .conference-title-button #conference-title-btn h3 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px;
}

#home-conference-banner .conference-title .conference-title-button #conference-title-btn h3:hover {
    color: #003A41;
}

#home-conference-banner .conference-title .conference-title-button #conference-title-btn h3:active {
    filter: brightness(90%);
}

#home-conference-banner .conference-title .conference-title-title {
    width: 50%;
    display: flex;
    margin-top: 30px;
}

#home-conference-banner .conference-title .conference-title-title h1 {
    font-family: Raleway;
    font-size: 45px;
    font-weight: 800;
    line-height: 60px;
    text-align: left;
    color: #003A41;
}

#home-conference-banner .conference-title .conference-title-subtitle {
    padding-top: 1%;
    width: 40%;
    margin-bottom: 30px;
}

#home-conference-banner .conference-title .conference-title-subtitle h3 {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 300;
    line-height: 33.5px;
    text-align: left;
    color: #003A41;
}

#home-conference-banner .conference-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

#home-conference-banner .conference-info .conference-title-card {
    background-color: var(--celeste, #08B0C0);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 300px;
    padding: 2%;
    padding-left: 3%;
}

#home-conference-banner .conference-info .conference-title-card .conference-title-card-subheader h3 {
    font-family: Raleway;
    font-size: 17px;
    font-style: italic;
    font-weight: 500;
    line-height: 33.5px;
    text-align: left;
    color: #FFFFFF;
}

#home-conference-banner .conference-info .conference-title-card .conference-title-card-header h1 {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    line-height: 33.5px;
    text-align: left;
    color: #A0F9FF;
    text-transform: uppercase;
}

/* - GRANTS - */

.grants-content {
    display: flex;
    align-items: center;
}

.grants-1 {
    grid-column: 1;
    flex: 1;
    align-self: center;
}

.grants-2 {
    grid-column: 2;
    flex: 1;
    align-self: center;
}

#grants-banner {
    background: #B5E4E8;
    padding-top: 3%;
    padding-bottom: 5%;
}

.grants-header {
    color: #0f767e;
    text-align: left;
    position: relative;
}

#grants-title {
    font-size: 32px;
    font-weight: 600;
}

.grants-subheader {
    margin-top: 5%;
    color: #4b5152;
    text-align: left;
    position: relative;
    max-width: 76%;
}

#grants-subtitle {
    font-size: 21px;
    font-weight: 400;
}

.grants-header-button {
    margin-top: 25px;
}

.grants-header-btn {
    background: #0f767e;
    border-radius: 200px;
    padding: 8px 30px 8px 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border: 0;
    text-decoration-line: none;
    width: 70%;
}

#grants-header-btn-text {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration-line: none;
}

#grants-btn-text:hover {
    color: #000000;
}

.grants-header-btn:active {
    filter: brightness(90%);
}

#grants-btn-text {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    position: relative;
}

.grants-text {
    background: url(../../images/home/home_background_6.svg) no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
    height: 400px;
    padding-top: 57px;
}

.grants-text h5 {
    margin-bottom: 5px;
}

#grants-monthly-title {
    padding-top: 3%;
    color: #0198a6;
    font-size: 15px;
    font-weight: 700;
    line-height: 35.5px;
    text-transform: uppercase;
    position: relative;
}

#grants-monthly-body {
    max-width: 280px;
    margin: 0 auto;
    color: #003a41;
    font-size: 42.162px;
    font-weight: 300;
    line-height: 43.243px;
}

#grants-monthly-link {
    color: #0198a6;
    text-align: left;
    position: relative;
    text-decoration: none;
}

#grants-monthly-link:hover {
    color: #38ddec;
}

#grants-monthly-link:active {
    filter: brightness(90%);
}

/* - INFO - */

#info-banner {
    background: url(../../images/home/home_background_4.svg) no-repeat;
    background-size: auto;
    background-position: right;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.column-hp {
    padding: 20px;
    border-radius: 8px;
}

#info-introduction {
    margin-top: 4%;
    margin-bottom: 4%;
}

.info-section-1 {
    color: #0f767e;
    text-align: left;
}

#info-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
}

.info-section-2 {
    color: #5e6162;
    text-align: center;
    position: relative;
}

#info-join {
    font-size: 21px;
    font-weight: 400;
    line-height: 28.5px;
}

.info-section-3 {
    text-align: right;
    color: #05373D;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#info-follow {
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 4.5px;
}

.info-section-3 .button-circular {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #25b8c7;
    color: #ffffff;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 45px;
    height: 45px;
    text-align: center;
    margin-bottom: 10px;
}


.button-circular:hover {
    color: #000000;
}

.button-circular:active {
    filter: brightness(90%)
}

/* - NEWS - */

#news-card {
    background: url(../../images/home/card_background.svg) no-repeat;
    background-color: #11b5c0;
    background-size: auto;
    background-position: -1% 80%;
    min-height: 750px;
    margin-bottom: 2%;
    border-radius: 20px;
}

.news-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.news-1 {
    grid-column: 1;
}

.news-2 {
    grid-column: 2;
}

#news-card-title {
    padding: 13%;
    max-width: 70%;
    color: #ffffff;
    text-align: left;
    font-size: 46px;
    font-weight: 400;
    line-height: 40px;
}

.news-button {
    margin-top: -60px;
    margin-left: 80px;
    width: 25%;
}

.news-btn {
    background: #FFFFFF;
    border-radius: 200px;
    padding: 8px 30px 8px 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

#news-btn-text:hover {
    color: #007f90;
}

.news-btn:active {
    filter: brightness(90%)
}

#news-btn-text {
    color: #05373D;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    border: none;
    outline: none;
    text-decoration-line: none;
}

.news-posts {
    padding: 5%;
}

#news-body-2 {
    display: none;
}

@media only screen and (max-width: 768px) {

    #home-banner {
        background: url(../../images/home/home_icon_new.svg) no-repeat;
        background-size: contain;
        background-position: center 90%;
        background-color: #9ad3e5;
        height: 680px;
    }

    .home-header-logo {
        text-align: center;
        z-index: 100;
    }

    .home-header-logo img {
        max-width: 100%;
        height: auto;
    }

    .home-body-text{
        padding-top: 50px;
    }

    #home-banner .content {
        width: 86% !important;
        margin-left: 7% !important;
    }

    #home-banner .content .home-content {
        display: block;
        padding-top: 30px;
        padding-bottom: 200px;
    }

    #home-banner .home-header #home-title {
        font-size: 22px !important;
        font-weight: 700;
        letter-spacing: 9px;
        margin-top: 0px;
    }

    #home-banner .home-subheader {
        max-width: 100%;
    }

    #home-banner .home-subheader #home-subtitle {
        color: #0d6d75;
        font-size: 18px !important;
        font-weight: 300;
        line-height: 25px;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .home-header-button {
        width: 100%;
        margin-bottom: 20px;
    }

    .home-header-button a {
        display: block;
        margin-bottom: 20px;
    }

    #home-banner .content .home-img {
        display: none;
    }

    #home-body {
        display: block;
    }

    .home-cards .card-container {
        display: block;
        width: 86%;
        margin-left: 7%;
    }

    .home-cards .card-container>.card {
        width: 100%;
        margin-bottom: 20px;
        padding-top: 40px;
    }

    .home-cards .card-container .card .card-image {
        margin: 0 auto;
    }

    .home-cards .card-container .card .card-content .card-title {
        text-align: center;
    }

    #partners-banner {
        background: url(../../images/home/home_background_2.svg) no-repeat;
        background-color: #05373df1;
        background-position: bottom right;
        background-size: contain;
        overflow: hidden;
        height: 800px;
    }

    .partners-text {
        margin-top: 10%;
        width: 86%;
        margin-left: 7%;
        max-width: max-content;
    }

    .partners-title {
        color: #ffffff;
        text-align: left;
        font-size: 18px;
    }

    .partners-subtitle {
        color: #51c7df;
        text-align: left;
        font-size: 18px;
    }

    .partners-button {
        width: 86%;
        margin-left: 7%;
    }

    .grants-content {
        display: block;
        width: 86%;
        margin-left: 7%;
        margin-top: 20px;
    }

    .grants-subheader {
        max-width: 100%;
        text-align: left;
        margin-top: 20px;
    }

    .grants-subheader #grants-subtitle {
        text-align: left;
        padding-top: 0px;
    }

    .grants-header-btn {
        width: 100%;
    }

    #grants-monthly-title {
        margin-top: 40px;
        font-size: 20px;
    }

    #grants-monthly-body {
        width: 75%;
        font-size: 28px;
        font-weight: 400;
        line-height: 1.3;
        text-align: center;
    }

    #grants-monthly-link {
        font-size: 20px;
    }

    #info-introduction .grid-container {
        display: block;
        width: 86%;
        margin-left: 7%;
        padding-top: 20px;
    }

    #info-introduction .column-hp {
        padding: 0px;
    }

    #info-join {
        font-size: 18px;
    }

    #info-title {
        font-size: 30px;
    }

    #info-introduction #info-join {
        text-align: left;
        padding: 10px 0px;
    }

    #info-follow {
        font-size: 16px;
        letter-spacing: 2px;
        text-align: left;
        width: 150%;
        margin-right: 5px;
    }

    .info-section-3 .button-circular {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #25b8c7;
        color: #ffffff;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
        width: 45px;
        height: 45px;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 2px;
    }

    #news-card {
        background: url(../../images/home/card_background.svg) no-repeat;
        background-color: #11b5c0;
        background-size: auto;
        background-position: -1% 80%;
        min-height: 750px;
        margin-bottom: 2%;
        border-radius: 20px;
        width: 95%;
        margin: 0 auto;
    }

    #news-body {
        display: none;
    }

    #news-body-2 {
        display: block;
    }

    #news-body-2 #news-card-title {
        padding: 13%;
        color: #ffffff;
        text-align: center;
        margin: 0 auto;
        font-size: 26px;
        font-weight: 400;
        line-height: 1.2;
    }

    #news-body-2 .news-button {
        margin-top: 30px;
        margin-left: 1px;
        width: 70%;
    }

    #news-body-2 .news-posts {
        margin-left: 15px;
        margin-top: 20px;
        width: 95%;
    }
}