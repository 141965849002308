#incubator .downloads {
    width: 100%;
    height: 50px;
    float: left;
    margin-top: 40px;
}

#incubator .download {
    background-color: #0F767E;
    border: 1px solid #ccc;
    border-radius: 200px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 30px;
    float: left;
    text-decoration: none;
    margin-right: 10px;
}

#incubator form{
    margin-top: 30px;
    float: left;
    width: 100%;
}

#incubator form #accept{
    margin-right: 10px;
}

#incubator .error-message{
    color: red;
    font-size: 12px;
}

#incubator ul{
    margin-top: 10px;
}
#incubator ul li{
    font-size: 14px;
}

#incubator ul li button{
    margin-left: 6px;
    border: none;
    color: red;
    margin-top: -2px;
}

#incubator .react-datepicker-wrapper{
    width: 100%;
}

#incubator .optional{
    width: 90%;
    margin-left: 20px;
}