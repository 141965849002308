* {
    font-family: 'Raleway', 'sans-serif';
}

.page-container {
    background-color: #EFF4F5;
}

#container {
    float: left;
    width: 100%;
    margin-top: 120px;
}

.content {
    width: 1240px !important;
    margin-left: calc(50% - 620px) !important;
    overflow: hidden;
}

.content-block {
    width: 1200px !important;
}

#breadcrumb {
    margin-top: 28px;
    font-size: 16px;
}

@media only screen and (max-width: 768px) {
    .content {
        width: 100% !important;
        margin-left: 0px !important;
        overflow: hidden;
    }

    .content-block {
        width: 100% !important;
    }
}

/* */

#contact {
    background: #81cadf;
    border-radius: 225.22px;
    padding: 9.01px 33.78px 9.01px 33.78px;
    display: flex;
    flex-direction: row;
    gap: 22.52px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

#contact2 {
    color: #05373d;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

#banner {
    height: 100px;
    background-color: #25474e;
    margin-top: 120px;
    color: white;
}

#title {
    height: 160px;
    background-color: #9ad3e5;
}

.title-subtitle {
    color: #0f767e;
    text-align: left;
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
}

.title-title {
    color: #05373d;
    text-align: left;
    text-transform: uppercase;
    font-size: 47px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 11px;
    position: relative;
    margin-top: 10px;
}

#about-body-1 {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

#about-body-2 {
    background: url(images/about/about_background_1.png) no-repeat;
    background-size: contain;
    background-position: center;
    height: 400px;
}

#banner h1 {
    line-height: 100px;
}

#content h2 {
    margin-top: 50px;
    font-size: 30px;
}

#content p {
    font-size: 20px;
    line-height: 24px;
}

.leaflet-container {
    width: 100%;
    height: 498px;
    z-index: 1;
}

.register {
    background-color: #25474e;
    border: solid 1px #25474e;
}

.ambassadorInfo {
    display: none;
    background-color: white;
    width: 100%;
    height: 498px;
    position: absolute;
    top: 0px;
    z-index: 999;
    padding: 10px;
}

.ambassadorInfo .close {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 22px;
    cursor: pointer;
}

.info-content {
    margin-top: 10px;
    float: left;
    margin-bottom: 20px;
}

.info-content .role {
    margin-bottom: 20px;
    font-weight: bold;
}

.info-content h6 {
    margin-bottom: 20px;
}

.info-content p {
    font-size: 14px !important;
    margin-left: 15px;
    line-height: 14px !important;
}

.info-content button {
    background-color: #25474e;
    border: solid 1px #25474e;
    position: absolute;
    bottom: 10px;
}

.form-control,
.select-input>div {
    box-shadow: none;
    border-color: var(--bs-border-color);
}

.form-control.is-invalid {
    box-shadow: none !important;
}

.select-input.is-invalid>div {
    border-color: red;
    box-shadow: none;
    background-position: right calc(3em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-radius: var(--bs-border-radius);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
}

.modal .error {
    color: var(--bs-form-invalid-border-color);
    font-size: 12px;
}

.content .map {
    margin-top: 60px;
    margin-bottom: 70px;
}

.content .map img {
    max-width: 70%;
    margin-left: 15%;
}

.content ul li {
    font-size: 20px;
    line-height: 24px;
}

.news>p {
    margin-top: 20px;
}