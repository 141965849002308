#contact-us-alt {
    background: url(../images/footer/footer_background.svg) 0% 50% no-repeat, #eff4f5;
    background-size: contain;
    background-position: left;
    padding-top: 40px;
    padding-bottom: 40px;
}

.contact-us-alt-content {
    display: flex;
    align-items: center;
}

.contact-us-alt-1 {
    grid-column: 1;
    flex: 1;
    align-self: center;
    padding-left: 150px;
    margin-right: -150px;
}

.contact-us-alt-2 {
    grid-column: 2;
    flex: 1;
    align-self: center;
    margin-top: 4%;
}

#contact-us-alt-img{
    width: 100px;
    height: 100px;
    background: url(../images/question_logo.svg);
}

#contact-us-alt-img img {
    width: 100px;
    height: 100px;
}

.contact-us-alt-title {
    color: #0F767E;
    font-size: 35px;
    font-weight: 600;
    line-height: 35.5px;
    margin-top: 20px;
    text-align: left;
    width: 250px;
}

#contact-us-alt-body {
    width: 500px;
    color: #05373D;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
}

.email {
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: left;
    margin-top: 30px;
}

.email a{
    background-color: #25b8c7;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

.email a:hover{
    color: #000000;
}

.email a:active {
    filter: brightness(90%);
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    #contact-us-alt {
        max-width: 100% !important;
    }

    .content {
        max-width: 100% !important;
    }

    #contact-us-alt .content .contact-us-alt-content {
        max-width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -60px;
    }
    #contact-us-alt-img {
        width: 100px;
        height: 101px;
        background: url(../images/question_logo.svg);
        margin-left: -200px;
    }

    .contact-us-alt-title {
        text-align: center;
        max-width: 230px;
        margin-left: -270px;
    }

    .contact-us-alt-2 {
        margin-top: 40px;
    }

    #contact-us-alt-body {
        color: #05373D;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        width: 88%;
        margin: 0 auto;
    }

    #email-1 a {
        background-color: #25b8c7;
        color: #fff;
        border: none;
        padding: 4px 10px;
        border-radius: 25px;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        margin-left: 72px;
    }

    #email-2 a{
        background-color: #25b8c7;
        color: #fff;
        border: none;
        padding: 4px 10px;
        border-radius: 25px;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-left: 55px;
    }

    #email-2 a:hover{
        color: #000000;
    }
    
    #email-2 a:active {
        filter: brightness(90%);
    }

}