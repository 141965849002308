.gn-members {
    padding-top: 30px;
    background-color: #EFF4F5;
}

.gn-members .gn-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    left: calc(50% + 180px);
    top: 120px;
}

.gn-members .gn-introduction .body {
    padding-top: 30px;
    width: 60%;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #05373D;
}

.gn-members .gn-introduction .footer {
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #05373D;
}

.gn-members .gn-members-carousel {
    margin-bottom: 50px;
}

.gn-members .gn-members-carousel .carousel .carousel-control-prev {
    color: #11B5C0;
}

.gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card {
    margin-left: 302px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #CDEBED;
    border-radius: 15px;
    max-width: 60%;
    height: 370px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card .carousel-img {
    width: 50%;
    flex-shrink: 0;
    margin-left: 30px;
}

.gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card .carousel-img img {
    width: 100%;
    display: block;
}

.gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card .carousel-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.carousel-member-name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-left: -180px;
    margin-top: 10px;
}

.carousel-member-name h3 {
    font-family: Raleway;
    font-size: 23px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #11B5C0;
    margin: 0;
    margin-left: -5px;
}

.carousel-member-body {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #373838;
    margin-left: -180px;
    margin-right: 30px;
}

.btn-linkedIn {
    display: flex;
    align-items: center;
    border-radius: 50%;
    border: none;
    background-color: #11B5C0;
    color: #ffffff;
    border: 0;
    margin-right: 5%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    justify-content: center;
    margin-right: 10px;
    font-size: 22px;
}

.btn-linkedIn:hover {
    color: #0b7178;
}

.btn-linkedIn:active {
    filter: brightness(90%);
}

@media only screen and (max-width: 768px) {

    .content {
        max-width: 100%;
        margin: 0 auto;
        padding: 10px;
    }

    .gn-icon img {
        display: none;
    }

    .gn-breadcrumb .content {
        padding-bottom: 10px;
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    .gn-introduction .content .body {
        width: 100%;
        font-family: Raleway;
        font-size: 20px;
        font-weight: 400;
        line-height: 27.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #05373D;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .gn-introduction .content .footer {
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
    }

    .gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card {
        margin-left: 60px;
        padding: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #CDEBED;
        border-radius: 15px;
        max-width: 70%;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-direction: column;
    }

    .gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card .carousel-img {
        width: 100%;
        align-items: center;
        margin-bottom: 15px;
    }

    .gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card .carousel-img img {
        width: 100%;
        height: 100%;
        margin-left: 35px;
    }

    .gn-members .gn-members-carousel .carousel .carousel-inner .carousel-item .carousel-card .carousel-info {
        width: 120%;
        padding-left: 210px;
    }

    .carousel-member-body {
        text-align: center;
    }

}