.opencalls #title {
    background-color: #B3F8FD;
}

.header-grants-oc {
    background-color: #B3F8FD;
}

.opencalls #title .title-title {
    width: 500px;
}

#open-calls-banner {
    background-color: #EFF4F5;
    margin-top: 30px;
}

.open-calls-content {
    display: flex;
    align-items: center;
}

.open-calls-content-2 {
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-bottom: -1%;
}

.open-calls-1 {
    grid-column: 1;
    flex: 1;
    align-self: center;
}

.open-calls-text {
    width: 750px;
    flex: none;
}

.open-calls-2 {
    grid-column: 2;
    flex: 1;
    align-self: center;
}

.open-calls-image-big {
    position: absolute;
    top: 100px;
    right: calc((100% - 1240px) / 2);
}

.open-calls-header {
    color: #0f767e;
    text-align: left;
    position: relative;
}

#open-calls-title {
    font-size: 26px;
    font-weight: 500;
    line-height: 33.5px;
}

.open-calls-subheader {
    color: #5e6162;
    text-align: left;
    position: relative;
}

#open-calls-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
}

.open-calls-header-button {
    margin-top: 5%;
}

.open-calls-header-btn {
    background: #0f767e;
    border-radius: 200px;
    padding: 8px 30px 8px 30px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    text-decoration-line: none;
}

#open-calls-header-btn-text {
    color: #FFF;
    font-size: 500;
    text-align: center;
    text-transform: uppercase;
}

#open-calls-header-btn-text:hover {
    color: #000000;
}

.open-calls-header-btn:active {
    filter: brightness(90%);
}

.open-calls-img {
    background: url(../../images/grants/grants_background_1.svg) no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
    height: 560px;
    width: 560px;
}

.open-calls-back {
    width: 260px;
    height: 260px;
    background-color: white;
    border-radius: 130px;
    margin-top: 150px;
    margin-left: 150px;
    float: left;
}

#open-calls-body {
    padding-top: 34px;
    margin: 0 auto;
    color: #003A41;
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    position: relative;
    text-align: left;
    width: 200px;
    text-align: center;
    padding-left: 0px;
}

#open-calls-link {
    color: #0198a6;
    position: relative;
    text-decoration: none;
    margin-top: 10px;
    display: block;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

#open-calls-link:hover {
    color: #00bccd;
}

#open-calls-link:active {
    filter: brightness(90%);
}

.orri-map-grants {
    margin-top: 5%;
}

.break {
    width: 100%;
    height: 1px;
    background-color: #C4C4C4;
    margin-top: 100px;
}

#orri-map-grants-title {
    color: #05373D;
    font-size: 23px;
    font-weight: 600;
    line-height: 31px;
    float: left;
    width: 220px;
    padding-top: 100px;
    padding-right: 20px;
}

.orri-map-grants-container {
    float: left;
    width: calc(100% - 260px);
    padding-left: 20px;
}

.omg-card {
    padding: 20px;
    box-sizing: border-box;
    float: left;
}

.omg-card:last-child {
    padding-right: 0px;
    padding-left: 30px;
}

.card-icon {
    background: url(../../images/grants/circle.svg) no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 112px 112px;
    height: 112px;
    width: 112px;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -80px;
    left: 30px;
}

.card-icon img {
    padding-top: 20px;
}

.omg-card {
    background-color: #EFF4F5;
    overflow: hidden;
    width: 50%;
    padding-top: 100px;
}

.omg-card-content {
    padding: 30px 30px 100px 30px;
    position: relative;
    border-radius: 16.601px;
    border: 1.107px solid #05373D;
    height: 400px;
    float: left;
}

.omg-card-header {
    padding-top: 50px;
    float: left;
    width: 100%;
}

.orri-map-grants-cards-title {
    color: #0F767E;
    font-size: 34px;
    font-weight: 600;
    line-height: 32.648px;
    margin-bottom: 22px;
    float: left;
    text-transform: uppercase;
}

.orri-map-grants-cards-subtitle {
    color: #11B5C0 !important;
    font-size: 22px;
    font-weight: 500;
    line-height: 32.648px;
    width: 100%;
    float: left;
    margin-bottom: 22px !important;
}

.orri-map-grants-cards-text {
    color: #5E6162;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.5px;
    float: left;
    margin-bottom: 22px;
}

.omg-card .omg-card-content .orri-map-grants-cards-btn {
    padding: 8px 30px;
    border-radius: 200px;
    background: #0F767E;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    float: left;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-decoration: none;
}

.omg-card .omg-card-content .orri-map-grants-cards-btn:hover {
    color: #000000;
}

.omg-card .omg-card-content .orri-map-grants-cards-btn:active {
    filter: brightness(90%);
}

#orri-map-grants-cards-btn-2 {
    margin-top: 30px;
}

hr {
    height: 1px;
    margin-top: 60px;
    float: left;
}

.application-banner {
    margin-top: 60px;
    padding-bottom: 100px;
}

#application-1 {
    max-width: 90%;
}

#application-1 a {
    color: #0F767E;
    font-size: 20px;
}

#application-1 img {
    margin-top: 30px;
}

#application-section-1 {
    color: #05373D;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.5px;
}

#application-section-2 {
    color: #0F767E;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
    margin-bottom: 20px;
}

#application-section-3 {
    color: #5E6162;
    font-size: 20px;
    font-weight: 400;
    line-height: 26.5px;
}

#application-section-4 {
    color: #0F767E;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
}

#application-section-5 {
    color: #5E6162;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
}

#application-section-4 li,
#application-section-5 li {
    padding-top: 9px;
}

.calendar-banner {
    background: url(../../images/grants/grants_background_2.svg) no-repeat;
    background-color: #05373D;
    background-size: contain;
    background-position: left;
    text-align: center;
}

.calendar-introduction {
    margin-top: 5%;
}

#calendar-introduction-1 {
    color: #11B5C0;
    text-align: center;
    font-size: 25.8px;
    font-weight: 600;
    line-height: 31.95px;
    max-width: 65%;
    margin: 0 auto;
    margin-top: 100px;
}

#calendar-introduction-2 {
    color: #EBEBEB;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    max-width: 60%;
    margin: 0 auto;
    margin-top: 2%;
}

#calendar-introduction-3 {
    color: #FFF;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    line-height: 31.5px;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 50px;
}

.calendar-schema {
    margin-top: 60px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 250px;
}

.calendar-schema .line {
    width: 100%;
    height: 6px;
    background: url(../../images/line.svg);
    background-size: cover;
    position: absolute;
    top: 94px;
    z-index: 2;
    left: 60px;
}

.column {
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    width: 20%;
}

.column-title {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 700;
    line-height: 13.876px;
}

.column-subtitle {
    color: #11B5C0;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
}

.column-node {
    color: #0F767E;
    width: 64px;
    height: 64px;
    background-color: #cdeaf0;
    border-radius: 32px;
    position: absolute;
    z-index: 5;
    left: 50%;
    margin-right: auto;
    margin-left: -32px;
    margin-top: 10px;
    margin-bottom: 135px;
    font-size: 17px;
    font-weight: 500;
    line-height: 13.88px;
    text-align: center;
    padding-top: 25px;
}

.evaluation-banner {
    background: url(../../images/grants/grants_background_3.svg) no-repeat;
    background-color: #11b4c04e;
    background-size: contain;
    background-position: 80% 20%;
    text-align: left;
    min-height: 400px;
}

.evaluation-introduction {
    margin-top: 55px;
}

.evaluation-schema {
    margin-bottom: 55px;
}

#evaluation-introduction-1 {
    color: #11B5C0;
    font-size: 26px;
    font-weight: 600;
    line-height: 31.95px;
    padding-left: 40px;
}

#evaluation-introduction-2 {
    color: #5E6162;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
    max-width: 60%;
    padding-left: 40px;
}

.evaluation-container {
    display: flex;
    justify-content: space-between;
    margin: 40px;
}

.ev-card {
    background-color: #0F767E;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 307px;
}

.ev-card:last-child {
    width: 480px;
}

.ev-card-content {
    padding: 20px 35px;
}

.evaluation-cards-title {
    color: #A0F9FF !important;
    font-size: 47.7px;
    font-weight: 300;
    line-height: 31.043px;
}

.evaluation-cards-text {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-top: 20px;
}

.evaluation-schema a {
    display: table;
    margin: auto;
}

.general-banner {
    margin-top: 60px;
    margin-bottom: 60px;
    border-bottom: solid 1px #b5b5b5;
}

.boosters,
.incubators {
    margin-top: 60px;
    margin-bottom: 60px;
}

.boosters {
    background-color: #e6eaeb;
}

.boosters .content {
    padding-top: 50px;
    padding-bottom: 100px;
}

.incubators {
    margin-bottom: 150px;
}

#boosters-1 {
    max-width: 85%;
    margin-top: 7%;
}

#boosters-2 {
    max-width: 85%;
    margin-top: -8%;
}

#boosters-2-2 {
    max-width: 85%;
    margin-top: -15%;
}

#boosters-section-1 {
    color: #11B5C0;
    font-size: 26px;
    font-weight: 600;
    line-height: 23.5px;
    margin-bottom: 5%;
}

#boosters-section-2 {
    color: #05373D;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.5px;
    margin-bottom: 5%;
}

#boosters-section-3 {
    color: #5E6162;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 5%;
}

#boosters-section-3-2 {
    color: #5E6162;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

#boosters-section-4 {
    color: #05373D;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

#boosters-section-5 {
    margin-top: 1%;
    margin-bottom: 5%;
}

#boosters-section-6 {
    color: #05373D;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.5px;
    margin-top: -20px;
}

#boosters-section-7 {
    color: #05373D;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.5px;
    margin-top: 0px;
}

#boosters-section-7-2 {
    color: #05373D;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.5px;
    margin-top: 70px;
}

#boosters-section-7-3 {
    color: #05373D;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.5px;
    margin-top: 70px;
}

.arrow-btn-date {
    position: absolute;
    margin-left: 146px;
    margin-top: 5px;
}

.arrow-btn-date-2 {
    position: absolute;
    margin-left: 186px;
    margin-top: 5px;
}

.arrow-btn-date-3 {
    position: absolute;
    margin-left: 61px;
    margin-top: 65px;
    z-index: 2000;
}

.arrow-btn-date-4 {
    position: absolute;
    margin-left: 125px;
    margin-top: 5px;
}

#arrow-btn-link {
    border-radius: 50%;
    border: none;
    background-color: #25B8C7;
    color: #ffffff;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#arrow-btn-link:hover {
    background-color: #39d7e9;
}

#arrow-btn-link:active {
    background-color: #169cab;
}

.arrow-btn {
    border: 0;
    border-radius: 50%;
    background-color: #25B8C7;
    height: 36px;
    width: 36px;
    margin-left: -27px;
    margin-top: 5px;
    color: #ffffff;
}

.arrow-btn svg {
    margin-bottom: 3px;
}

.arrow-btn:hover {
    background-color: #38d7e8;
}

.arrow-btn:active {
    background-color: #1e9dab;
}

.arrow-btn-2 {
    border: 0;
    border-radius: 50%;
    background-color: #25B8C7;
    height: 36px;
    width: 36px;
    margin-left: -307px;
    margin-top: 5px;
    color: #ffffff;
}

.arrow-btn-2 svg {
    margin-bottom: 3px;
}

.arrow-btn-2:hover {
    background-color: #38d7e8;
}

.arrow-btn-2:active {
    background-color: #1e9dab;
}

.calendar-date-sp {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background: rgba(81, 199, 223, 0.26);
    padding: 6px 19px;
    color: #0F767E;
    font-size: 20px;
    font-weight: 500;
    width: fit-content;
    text-align: left;
    margin-right: 1%;
    float: left;
    margin-top: 1%;
    padding-right: 40px;
}

.calendar-date-sp-2 {
    border-radius: 7px;
    background: rgba(81, 199, 223, 0.26);
    padding: 6px 19px;
    color: #0F767E;
    font-size: 20px;
    font-weight: 500;
    width: fit-content;
    text-align: left;
    margin-right: 1%;
    float: left;
    margin-top: 1%;
    margin-left: 20px;
}

.calendar-date {
    border-radius: 7px;
    background: rgba(81, 199, 223, 0.26);
    padding: 6px 19px;
    color: #0F767E;
    font-size: 20px;
    font-weight: 500;
    width: fit-content;
    text-align: left;
    margin-right: 1%;
    float: left;
    margin-top: 1%;
}

.calendar-date-2 {
    border-radius: 7px;
    background: rgba(81, 199, 223, 0.26);
    padding: 6px 19px;
    color: #0F767E;
    font-size: 20px;
    font-weight: 500;
    width: fit-content;
    text-align: left;
    margin-right: 1%;
    float: left;
    margin-top: 1%;
    margin-left: 5%;
}

#boosters-section-dates-colums {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.calendar-date-card {
    border-radius: 7px;
    background: rgba(81, 199, 223, 0.26);
    padding: 6px 19px;
    color: #0F767E;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.5px;
    margin-right: 1%;
    float: right;
}

#link-online-form:hover {
    color: #00bccd;
}

#break-2 {
    display: none;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    #breadcrumb .content {
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        max-width: 100% !important;
        margin-bottom: 320px;
    }

    .open-calls-image-big {
        position: relative;
        top: 385px;
        left: 0;
        margin-left: 33px;
    }

    .open-calls-img {
        background: url(../../images/grants/grants_background_1.svg) no-repeat;
        background-size: contain;
        background-position: center;
        text-align: center;
        position: absolute;
        height: 320px;
        width: 320px;
    }

    .open-calls-back {
        width: 160px;
        height: 160px;
        background-color: white;
        border-radius: 130px;
        margin-top: 80px;
        margin-left: 80px;
        float: left;
    }

    #open-calls-body {
        padding-top: 8px;
        margin: 0 auto;
        color: #003A41;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        position: relative;
        text-align: left;
        width: 150px;
        text-align: center;
    }

    #open-calls-link {
        color: #0198a6;
        position: relative;
        text-decoration: none;
        margin-top: 5px;
        display: block;
        font-weight: 600;
        font-size: 13px;
        text-align: center;
    }

    #open-calls-link:hover {
        color: #00bccd;
    }

    #open-calls-link:active {
        filter: brightness(90%);
    }

    #open-calls-title {
        width: 330px;
        margin-left: 15px;
    }

    #open-calls-subtitle {
        width: 330px;
        margin-left: 15px;
    }

    .open-calls-header-button {
        width: 300px;
    }

    #orri-map-grants-title {
        width: 100%;
        margin-top: -50px;
        text-align: center;
        margin-left: 10px;
        margin-bottom: 40px;
    }

    #orri-map-grants-schema {
        width: 100% !important;
    }

    .orri-map-grants-container {
        display: flex;
        flex-direction: column;
        width: 2000%;
    }

    .omg-card {
        margin-left: -35px;
    }

    #omg-card-2 {
        margin-left: -44px;
    }

    .omg-card:last-child {
        margin-bottom: -20px;
    }

    .omg-card-content {
        padding: 10px 20px 20px 20px;
        position: relative;
        border-radius: 16.601px;
        border: 1.107px solid #05373D;
        height: 350px;
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .orri-map-grants-cards-title {
        font-size: 26px;
    }

    .break {
        margin-top: 40px;
    }

    #break-2 {
        display: block;
    }

    .boosters .content {
        border-bottom: solid 0px #5E6162;
        margin-bottom: -60px;
    }

    #open-calls-banner .content {
        max-width: 100% !important;
    }

    .open-calls-text {
        width: 100%;
    }

    #open-calls-title {
        text-align: center;
    }

    #open-calls-subtitle {
        margin-top: 30px;
        text-align: center;
    }

    .open-calls-header-button {
        margin-top: 50px;
        margin-left: 28px;
    }

    .boosters,
    .incubators {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .incubators {
        margin-top: 80px;
    }

    .open-calls-content-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .open-calls-1 {
        margin-top: -20px;
    }

    .open-calls-text {
        width: 750px;
        flex: none;
    }

    .open-calls-2 {
        margin-top: -20px;
    }

    #boosters-1 {
        width: 360px;
        margin-left: 20px;
    }

    #boosters-2 {
        margin-top: 40px;
        width: 360px;
        margin-left: 20px;
        margin-bottom: -65px;
    }

    #boosters-section-5 {
        text-align: center;
    }

    #boosters-section-5 .calendar-date {
        display: inline-block;
        border-radius: 7px;
        background: rgba(81, 199, 223, 0.26);
        padding: 6px 19px;
        color: #0F767E;
        font-size: 20px;
        font-weight: 500;
        width: fit-content;
        text-align: center;
        margin-right: 1%;
        margin-top: 1%;
    }

    #boosters-section-5 .arrow-btn-date-4 {
        margin-top: 3px;
        margin-left: 126px;
    }

    #boosters-section-5 .calendar-date-2 {
        margin-left: 25px;
    }

    .boosters-section-3-class {
        margin-top: 50px;
    }

    #open-calls-2-id {
        margin-top: 20px;
    }

    .boosters-section-3-class-2 {
        padding-bottom: 70px;
    }

    .calendar-banner {
        width: 100%;
    }

    .calendar-banner .content {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .calendar-banner .content .calendar-introduction {
        margin-top: -70px;
    }

    #calendar-introduction-1 {
        color: #11B5C0;
        text-align: center;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        max-width: 90% !important;
    }

    #calendar-introduction-2 {
        color: #EBEBEB;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 20px;
        max-width: 90% !important;
    }

    #calendar-introduction-3 {
        margin-top: 30px;
        color: #FFFFFF;
        text-align: center;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        max-width: 90% !important;
    }

    .calendar-banner .content .calendar-schema {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 650px;
        margin-top: 30px !important;
        margin: 0 auto;
        margin-left: 35px;
    }

    .calendar-banner .content .calendar-schema .column {
        margin-top: 10px;
    }

    .calendar-banner .content .calendar-schema .line {
        display: none;
    }

    .calendar-banner .content .calendar-schema .column-subtitle {
        margin-top: 10px;
    }

    .calendar-banner .content .calendar-schema .column-node {
        margin-left: 150px;
        margin-top: -65px;
    }

    .evaluation-banner .content {
        width: 100% !important;
    }

    .evaluation-banner .content .evaluation-introduction {
        max-width: 100%;
    }

    #evaluation-introduction-1 {
        margin-top: -30px;
        color: #11B5C0;
        font-family: Raleway;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-left: -30px;
    }

    #evaluation-introduction-2 {
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-left: -30px;
        margin-top: 20px;
        max-width: 100% !important;
    }

    .evaluation-cards {
        margin-bottom: -30px;
    }

    .evaluation-container {
        display: flex;
        flex-direction: column;
    }

    .ev-card {
        background-color: #0F767E;
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
        width: 120%;
        margin-left: -30px;
        margin-bottom: 10px;
    }

    .ev-card:last-child {
        width: 120%;
    }

    .ev-card-content {
        padding: 20px 35px;
    }

    .evaluation-cards-title {
        color: #A0F9FF !important;
        font-size: 47.7px;
        font-weight: 300;
        line-height: 31.043px;
    }

    .evaluation-cards-text {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-top: 20px;
    }

    .arrow-btn-date {
        margin-top: 3px;
    }

    .arrow-btn-date-2 {
        margin-top: 3px;
    }

    .arrow-btn-date-3 {
        margin-top: -51px;
        margin-left: 240px;
    }

    .column-subtitle {
        width: 100px;
    }

    #boosters-section-3-2 {
        margin-top: 60px;
    }

}