#contact-us {
    background: url(../images/footer/footer_background.svg) 0% 50% no-repeat, #b5e4e8;
    padding-top: 70px;
    padding-bottom: 70px;
}

.grants #contact-us,
.orrimap #contact-us {
    background: url(../images/footer/footer_background.svg) 0% 50% no-repeat, #b5e4e8;
    padding-top: 70px;
    padding-bottom: 70px;
}

.project-resources #contact-us {
    background: 
        url(../images/footer/footer_background.svg) 0% 50% no-repeat,
        url(../images/footer/footer_background_2.svg) 111% 50% no-repeat,
        #EFF4F5;
    padding-top: 70px;
    padding-bottom: 70px;
}

.aboutPage #contact-us {
    background: url(../images/footer/footer_background.svg) 0% 50% no-repeat, #EFF4F5;
    padding-top: 70px;
    padding-bottom: 70px;
}

#contact-us .sub-footer-question {
    text-align: center;
}

#contact-us .sub-footer-question-logo {
    text-align: center;
    margin-top: 60px;
}

#contact-us .sub-footer-question-logo {
    width: 100px;
    height: 101px;
    background: url(../images/question_logo.svg);
    margin-left: auto;
    margin-right: auto;
}

#contact-us .title {
    color: #0F767E;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    line-height: 35.5px;
    margin-top: 33px;
}

#contact-us .body {
    color: var(--Verde-texto, #05373D);
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
    margin-top: 20px;
}

#contact-us .email {
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
    margin-top: 50px;
}

#contact-us .email a {
    background-color: #25b8c7;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

#contact-us .email a:hover {
    color: #000000;
}

#contact-us .email a:active {
    filter: brightness(90%);
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    #contact-us {
        background: none;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #contact-us .sub-footer-question-logo {
        text-align: center;
        margin-top: 60px;
        width: 100px;
        height: 101px;
        background: url(../images/question_logo.svg);
        margin-left: auto;
        margin-right: auto;
    }

    #contact-us .title {
        color: #0F767E;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        line-height: 33px;
        padding-top: 20px;
        width: 70%;
        margin: 0 auto;
    }

    #contact-us .body {
        color: #05373D;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.5px;
        width: 90%;
        margin: 0 auto;
        padding-top: 15px;
    }

    #contact-us .email {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    #contact-us .email a {
        background-color: #25b8c7;
        color: #fff;
        border: none;
        padding: 8px 30px;
        border-radius: 25px;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
    }

    #contact-us .email a:hover {
        color: #000000;
    }

    #contact-us .email a:active {
        filter: brightness(90%);
    }
}