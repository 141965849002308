#post-page-link {
    color: #0198A6;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration: none;
    padding-bottom: 20px;
}

#post-page-link:hover {
    color: #03c7d8; 
}

#post-page-link:active {
    filter: brightness(90%);
}

/* ----------------------- MOBILE ----------------------- */

.content {
    color: #5E6162;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}