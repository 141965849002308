#cfe-header .cfe-schema {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 700px;
}

#cfe-header .cfe-body {
    grid-column: 1;
    max-width: 90%;
}

#cfe-header .cfe-logo {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 100px;
}

#cfe-header .cfe-1 {
    color: #05373D;
    font-size: 26px;
    font-weight: 600;
    line-height: 27.5px;
    margin-top: 13%;
    margin-bottom: 7%;
    width: 120%;
}

#cfe-header .cfe-2 {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.5px;
    text-align: left;
    color: #0F767E;
    width: 120%;
}

#cfe-header .cfe-3 {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 26.5px;
    text-align: left;
    color: #5E6162;
    margin-top: 7%;
    margin-bottom: 20%;
    width: 120%;
}

#current-calls-objectives .content .title-2 {
    margin-top: 6.5%;
    margin-bottom: 2%;
    color: #A0F9FF;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
}

#current-calls-objectives .content .body {
    max-width: 92%;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
}

#current-calls-objectives .content .process {
    margin-top: 3%;
    margin-bottom: 3%;
    color: #11B5C0;
    text-align: center;
    font-size: 22.295px;
    font-weight: 600;
    line-height: 29.108px;
}

#current-calls-objectives .content .summary {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 200px;
}

.cc-steps {
    display: flex;
    justify-content: center;
    margin-left: 30px;
}
  
.cc-step-1, .cc-step-2, .cc-step-3 {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
.cc-step-1, .cc-step-2, .cc-step-3 img {
    margin-right: 20px;
}

#summary-text {
    margin-top: -50px;
    text-align: left;
    color: #A0F9FF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.5px;
    margin-left: 20px;
    max-width: 300px;
}
  
#summary-text-2 {
    text-align: left;
    color: #A0F9FF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.5px;
    margin-left: 20px;
    max-width: 300px;
}

#summary-text-3 {
    text-align: left;
    color: #A0F9FF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.5px;
    max-width: 300px;
}

#cc-step-1-icon {
    margin-top: -50px;
}

#cc-step-2-icon {
    margin-top: -50px;
}

#cc-step-3-icon {
    margin-left: 20px;
    margin-top: -50px;
}

.remuneration-header {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 200px;
}

.remuneration-header-1 {
    grid-column: 1;
}

.remuneration-header-2 {
    grid-column: 2;
    margin-left: 20px;
}

.remuneration-header-3 {
    grid-column: 3;
    margin-left: 60px;
}

.remuneration-header {
    margin-top: 5%;
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}

#remuneration-header-title {
    color: #0F767E;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
    margin: 0 auto;
    text-align: left;
}

#remuneration-header-icon { 
    margin-left: 15%;
}

#remuneration-header-text {
    color: #5E6162;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.5px;
    width: 90%;
    margin: 0 auto;
    text-align: left;
}

.remuneration-card-structure{
    padding: 5%;
    border-radius: 15px;
    background: #E1EDEF;
    mix-blend-mode: multiply;
    float: left;
    display: grid;
    grid-template-columns: 5fr 2fr;
    margin-bottom: 10%;
}

.remuneration-card-body {
    grid-column: 1;
}

.remuneration-card-dates {
    grid-column: 2;
}

#remuneration-card-body-title {
    color: #0F767E;
    font-size: 26px;
    font-weight: 600;
    line-height: 35.5px;
}

#remuneration-card-body-subtitle {
    margin-top: 3%;
    color: #05373D;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.5px;
}

#remuneration-card-body-text {
    margin-top: 3%;
    color: #05373D;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
}

#remuneration-card-dates-structure {
    padding: 10%;
    border-radius: 19px;
    border: 1px solid #0198A6;
    min-height: 200px;
}

#remuneration-card-dates-title {
    color: #05373D;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
}

#remuneration-card-dates-title-2 {
    margin-top: 7%;
    color: #05373D;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
}

#remuneration-card-dates-month {
    margin-top: 7%;
    color: #0F767E;
    font-size: 38px;
    font-weight: 500;
    line-height: 24.547px;
}

#remuneration-card-dates-year {
    margin-top: 7%;
    color: #0F767E;
    font-size: 41.053px;
    font-weight: 300;
    line-height: 24.547px;
}

#remuneration-card-body-buttons {
    margin-top: 3%;
}

#apply-btn {
    border-radius: 20px;
    background: #0F767E;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-left: 0px !important; 
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

#apply-btn-2 {
    border-radius: 20px;
    background: #11B5C0;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-left: 20px; 
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

#apply-btn-2:hover {
    color: #000000;
}

#apply-btn-2:active {
    filter: brightness(90%);
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .evaluators-breadcrumb {
        height: 20px;
    }

    #cfe-header .content {
        background: url(../../images/call-for-evaluators/call_for_evaluators_bg_1.svg) no-repeat;
        background-color: #EFF4F5;
        background-size: contain;
        background-position: left;
        text-align: center;
        width: 100% !important;
    }

    #cfe-header .content .cfe-schema .cfe-body {
        min-width: 180% !important;
        margin-left: 15px;
        margin-top: -10px;
    }

    #cfe-header .cfe-1 {
        margin-top: 45px;
        width: 100%;
    }
    
    #cfe-header .cfe-2 {
        text-align: center;
        width: 100%;
    }
    
    #cfe-header .cfe-3 {
        text-align: center;
        width: 100%;
    }

    .cfe-background {
        display: none;
    }

    #current-calls-objectives {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    #current-calls-objectives .content .title-2 {
        padding-bottom: 20px;
        width: 95%;
        margin: 0 auto;
    }

    #current-calls-objectives .content .body {
        padding-top: 20px;
        padding-bottom: 30px;
        width: 95%;
        margin: 0 auto;
    }

    #current-calls-objectives .content .process {
        padding-top: 10px;
        padding-bottom: 30px;
        width: 85%;
        margin: 0 auto;
    }

    #current-calls-objectives .content .cc-steps {
        padding-top: 70px;
        display: flex;
        flex-direction: column;
    }

    .cc-step-1 {
        margin-bottom: 30px;
    }

    .cc-step-2 {
        margin-bottom: 30px;
    }

    .cc-step-2 #summary-text-2 {
        margin-left: 15px;
    }

    .cc-step-3 {
        margin-left: -10px;
    }

    .cc-step-3 #summary-text-3 {
        margin-left: 0px;
    }

    .remuneration .content .remuneration-header {
        display: flex;
        flex-direction: column;
        margin-left: -5px;
    }

    #remuneration-header-title {
        text-align: center;
        margin-top: 20px;
    }

    #remuneration-header-icon {
        margin: 0 auto;
        margin-left: -5px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #remuneration-header-text {
        margin-left: -10px;
        text-align: center;
    }

    .remuneration .content .remuneration-card .remuneration-card-structure {
        width: 100%;
        height: 890px;
        display: flex;
        flex-direction: column;
    }

    .remuneration .content .remuneration-card .remuneration-card-structure .remuneration-card-body {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    #remuneration-card-body-subtitle {
        padding-top: 10px;
    }

    #remuneration-card-body-text {
        padding-top: 10px;
    }

    #remuneration-card-body-buttons {
        margin-top: 350px;
        display: flex;
        flex-direction: column;
    }

    #remuneration-card-body-buttons #apply-btn {
        border-radius: 20px;
        background: #0F767E;
        border: none;
        cursor: pointer;
        padding: 10px 15px;
        box-sizing: border-box;
        margin-left: 0px !important; 
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        text-decoration: none;
        margin-bottom: 10px;
    }

    #remuneration-card-body-buttons #apply-btn-2 {
        border-radius: 20px;
        background: #11B5C0;
        border: none;
        cursor: pointer;
        padding: 10px 15px;
        box-sizing: border-box;
        margin-left: 0px !important; 
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        text-decoration: none;
    }

    .remuneration .content .remuneration-card .remuneration-card-structure .remuneration-card-dates {
        margin-top: -410px;
    }

}