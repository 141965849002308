/* - REGISTRATION - */

#registration-banner {
    background: url(../images/home/home_background_5.svg) no-repeat;
    background-size: auto;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 150px;
}

.contact #registration-banner {
    background: url(../images/home/home_background_5.svg) no-repeat, #E1EDEF;
    background-size: auto;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 150px;
}

.registration-title {
    text-align: center;
    color: #11b5c0;
    font-size: 36px;
    font-weight: 400;
}

.registration-subtext {
    font-size: 41px;
    font-weight: 700;
    line-height: 35.5px;
    text-transform: uppercase;
    text-align: center;
    color: #11B5C0;
    margin-top: 11px;
    margin-bottom: 20px;
}

#subscribe-section {
    max-width: 80%;
}

#subscribe-form {
    display: flex;
    text-align: center;
    margin: auto;
    max-width: 70%;
}

#form-control {
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: solid 1px #A0F9FF;
    padding-left: 20px;
    font-size: 16px;
    color: black;
}

#subscribe-btn {
    border-radius: 20px;
    background: #25b8c7;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-left: 20px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

#subscribe-btn:hover {
    color: #000000;
}

#subscribe-btn:active {
    filter: brightness(90%);
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    #registration-banner {
        background: url(../images/home/home_background_5.svg) no-repeat;
        background-size: contain;
        background-position: center;
        text-align: center;
        width: 86%;
        margin-left: 7%;
        padding: 80px 0px;
    }

    .registration-title {
        text-align: center;
        color: #11b5c0;
        font-size: 26px;
        font-weight: 400;
    }

    .registration-subtext {
        font-size: 30px;
        font-weight: 700;
        line-height: 35px;
        text-transform: uppercase;
        color: #11B5C0;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #subscribe-btn {
        border-radius: 20px;
        background: #25b8c7;
        border: none;
        cursor: pointer;
        padding: 10px 30px;
        box-sizing: border-box;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }

    #subscribe-form {
        display: inline;
    }
}