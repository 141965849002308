.orrimap #content .left {
    width: 75%;
    float: left;
    border: solid 1px #bfdae0;
    border-right: none;
    margin-bottom: 14px;
}

.orrimap #content .right {
    width: 25%;
    height: 500px;
    float: right;
    border: solid 1px #bfdae0;
    border-left: none;
    margin-bottom: 14px;
    position: relative;
}

.orrimap #content .right>h4,
.orrimap #content .right form button {
    margin-top: 10px;
    margin-left: 10px;
}

.orrimap #content .right form label,
.orrimap #content .right form .select-input {
    padding-left: 10px;
    padding-right: 10px;
}

.orrimap #content .right form button.find {
    background-color: #25474e;
    border: solid 1px #25474e;
}

.orrimap #content .right form button.reset {
    background-color: #76a3ac;
    border: solid 1px #76a3ac;
}

.orrimap #content .ambassadorInfo {
    display: none;
    background-color: white;
    width: 100%;
    height: 498px;
    position: absolute;
    top: 0px;
    z-index: 999;
    padding: 10px;
}

.orrimap #content .ambassadorInfo .close {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 22px;
    cursor: pointer;
}

.orrimap #content .info-content {
    margin-top: 10px;
    float: left;
    margin-bottom: 20px;
}

.orrimap #content .info-content .role {
    margin-bottom: 20px;
    font-weight: bold;
}

.orrimap #content .info-content h6 {
    margin-bottom: 20px;
}

.orrimap #content .info-content p {
    font-size: 14px !important;
    margin-left: 15px;
    line-height: 14px !important;
}

.orrimap #content .info-content button {
    background-color: #25474e;
    border: solid 1px #25474e;
    position: absolute;
    bottom: 10px;
}

.orrimap .select-input.is-invalid>div {
    border-color: red;
    box-shadow: none;
    background-position: right calc(3em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-radius: var(--bs-border-radius);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
}

.orrimap .modal .error {
    color: var(--bs-form-invalid-border-color);
    font-size: 12px;
}

.privacy-policy-checkbox {
    margin-top: 30px;
}

#checkbox {
    cursor: pointer;
}

.btn:disabled,
.btn[disabled] {
  background-color: #ccc;
  color: #666;
  border: 1px solid #999;
  cursor: not-allowed;
}

#register-btn{
    background-color: #25b8c7;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

#register-btn:hover {
    color: #000000;
}

#register-btn:active {
    filter: brightness(90%);
}

@media only screen and (max-width: 600px) {
    .map-section {
        border: 1px solid rgb(195, 195, 195);
        padding: 4%;
        max-height: 300px;
        margin-top: -50px;
    }

    #map-title {
        margin-bottom: 20px;
    }

    #finder_countries_selector {
        margin-bottom: 20px;
    }

    #find-btn {
        background-color: #25474e;
        border: solid 1px #25474e;
        margin-right: 10px;
    }

    #find-btn:hover {
        color: #000000;
    }

    #find-btn:active {
        filter: brightness(90%);
    }

    #reset-btn {
        background-color: #76a3ac;
        border: solid 1px #76a3ac;
    }

    #reset-btn:hover {
        color: #000000;
    }

    #reset-btn:active {
        filter: brightness(90%);
    }
}