#booster .downloads {
    width: 100%;
    height: 50px;
    float: left;
    margin-top: 40px;
}

#booster .download {
    background-color: #0F767E;
    border: 1px solid #ccc;
    border-radius: 200px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 30px;
    float: left;
    text-decoration: none;
    margin-right: 10px;
}

#booster .download:hover {
    color: #000000;
}

#booster .download:active {
    filter: brightness(90%);
}

#booster form{
    margin-top: 30px;
    float: left;
    width: 100%;
}

#booster form #accept{
    margin-right: 10px;
}

#booster .error-message{
    color: red;
    font-size: 12px;
}

#booster ul{
    margin-top: 10px;
}
#booster ul li{
    font-size: 14px;
}

#booster ul li button{
    margin-left: 6px;
    border: none;
    color: red;
    margin-top: -2px;
}

#booster .react-datepicker-wrapper{
    width: 100%;
}

#booster .optional{
    width: 90%;
    margin-left: 20px;
}

.position-relative {
    position: relative;
}

.dropdown-wrapper {
    position: relative;
    display: inline-block;
    width: 100%; /* O ajustar según tu diseño */
}

.dropdown-wrapper select {
    appearance: none; /* Oculta la flecha por defecto */
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 2rem; /* Espacio para el icono */
}

.dropdown-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Para que no interfiera con los clics */
    font-size: 1rem;
    color: #6c757d; /* Ajusta según tu diseño */
}

/* ----------------------- MOBILE ----------------------- */

.mobile-header-booster {
    background: #c1f7fc;
}

#mobile-booster {
    min-height: 2050px;
}

#mobile-booster button{
    margin-bottom: 50px;
}

#mobile-booster .mobile-downloads {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#mobile-booster .download {
    background-color: #0F767E;
    border: 1px solid #ccc;
    border-radius: 200px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 17px;
    float: left;
    text-decoration: none;
    margin-bottom: 10px;
}

#mobile-booster .download:hover {
    color: #000000;
}

#mobile-booster .download:active {
    filter: brightness(90%);
}

#mobile-booster form{
    float: left;
    width: 100%;
}

#mobile-booster form #accept{
    margin-right: 10px;
}

#mobile-booster .error-message{
    color: red;
    font-size: 12px;
}

#mobile-booster ul{
    margin-top: 10px;
}
#mobile-booster ul li{
    font-size: 14px;
}

#mobile-booster ul li button{
    margin-left: 6px;
    border: none;
    color: red;
    margin-top: -2px;
}

#mobile-booster .react-datepicker-wrapper{
    width: 100%;
}

#mobile-booster .optional{
    width: 90%;
    margin-left: 20px;
}