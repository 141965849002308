.project-resources #title {
    background-color: #5ebcc4 !important;
}

.resources {
    background-color: #b5e4e8;
    padding-bottom: 105px;
}

.resources #breadcrumb {
    color: #5E6162;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.5px;
    margin-top: 2%;
    margin-bottom: 60px;
}

.project-resources .title-subtitle {
    color: white !important;
}

.project-resources .title-title {
    color: white !important;
}

.project-resources .title-logo {
    position: absolute;
    left: 900px;
    top: 100px
}

.resources #visual-materials-header {
    font-family: Raleway;
    font-size: 47px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 11px;
    text-align: left;
    color: #05373D;
    text-transform: uppercase;
    padding-bottom: 50px;
}

.resources .resource {
    background-color: #eff9fa;
    width: 380px;
    height: 220px;
    border-radius: 15px;
    position: relative;
    float: left;
    margin-right: 26px;
    margin-bottom: 26px;
    text-align: center;
}

.resources .resource:nth-child(3n + 2) {
    margin-left: 30px;
}

.resources .resource:nth-child(3n + 1) {
    margin-right: 0px;
}

.resources .resource .resource-title {
    font-size: 21px;
    font-weight: 600;
    line-height: 25.5px;
    color: #0F767E;
    padding: 44px 50px 0px 50px;
    width: 90%;
    margin: 0 auto;
}

.resources .resource .resource-subtitle-1 {
    font-family: Raleway;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 25.5px;
    text-align: center;
    color: #003A41;
    width: 90%;
    margin: 0 auto;
    margin-top: 33px;
}

.resources .resource .resource-subtitle-2 {
    font-family: Raleway;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 25.5px;
    text-align: center;
    color: #003A41;
    width: 90%;
    margin: 0 auto;
    margin-top: 8px;
}

.resources .resource .download {
    background-color: #0F767E;
    position: absolute;
    left: 105px;
    bottom: 39px;
    font-size: 18px;
    color: white;
    padding: 8px 30px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 200px;
    font-weight: 600;
}

.resources .resource .download:hover {
    color: #000000;
}

.resources .resource .download:active {
    filter: brightness(90%);
}

.resources .visual-materials,
.resources .press-release {
    padding-bottom: 300px !important;
}

.resources .press-release {
    padding-top: 240px !important;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    .project-resources .title-logo {
        position: absolute;
        left: 60px;
        top: 380px;
    }

    .project-resources .title-logo img {
        height: 250px;
        width: 250px;
    }

    .visual-materials {
        margin-top: -540px;
    }

    .resources #visual-materials-header {
        font-family: Raleway;
        font-size: 28px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 9px;
        text-align: left;
        color: #05373D;
        text-transform: uppercase;
        padding-bottom: 50px;
        padding-top: 500px;
        margin-left: 10px;
    }

    .visual-materials .visual-materials-posts {
        flex-direction: column;
    }

    .resources .resource {
        background-color: #eff9fa;
        width: 90%;
        height: 220px;
        border-radius: 15px;
        margin-bottom: 26px;
        margin-left: 20px;
    }

    .resources .resource:nth-child(3n + 2) {
        margin-left: 20px;
    }
    
    .resources .resource:nth-child(3n + 1) {
        margin-right: 0px;
    }

    .resources .resource .resource-title {
        font-size: 21px;
        font-weight: 600;
        line-height: 25.5px;
        color: #0F767E;
        padding: 44px 50px 0px 50px;
        width: 100%;
        margin: 0 auto;
    }

    .resources .resource .download {
        background-color: #0F767E;
        font-size: 18px;
        color: white;
        padding: 8px 30px;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 200px;
        font-weight: 600;
        margin-left: -22px;
    }
    
    .resources .resource .download:hover {
        color: #000000;
    }
    
    .resources .resource .download:active {
        filter: brightness(90%);
    }

}