/* ----------------------- MOBILE ----------------------- */

#mobile-evaluator {
    min-height: 1400px;
}

#mobile-evaluator button{
    margin-bottom: 50px;
}

#mobile-evaluator .mobile-downloads {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#mobile-evaluator .download {
    background-color: #0F767E;
    border: 1px solid #ccc;
    border-radius: 200px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 17px;
    float: left;
    text-decoration: none;
    margin-bottom: 10px;
}

#mobile-evaluator .download:hover {
    color: #000000;
}

#mobile-evaluator .download:active {
    filter: brightness(90%);
}

#mobile-evaluator form{
    float: left;
    width: 100%;
}

#mobile-evaluator form #accept{
    margin-right: 10px;
}

#mobile-evaluator .error-message{
    color: red;
    font-size: 12px;
}

#mobile-evaluator ul{
    margin-top: 10px;
}
#mobile-evaluator ul li{
    font-size: 14px;
}

#mobile-evaluator ul li button{
    margin-left: 6px;
    border: none;
    color: red;
    margin-top: -2px;
}

#mobile-evaluator .react-datepicker-wrapper{
    width: 100%;
}

#mobile-evaluator .optional{
    width: 90%;
    margin-left: 20px;
}