.content.news a{
    color: #25b8c7;
}

#post-page-title {
    padding-top: 50px;
    color: #0F767E;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.5px;
}

#post-page-author {
    color: #05373D;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.5px;
    margin-bottom: 40px;
}

#post-page-body {
    color: #5E6162;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.5px;
    text-align: justify;
}

#post-page-body .wp-block-button {
    border-radius: 20px;
    background: #25b8c7;
    max-width: 200px;
    border: none;
    cursor: pointer;
    padding: 10px 30px;
    box-sizing: border-box;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-decoration: none;
}

#post-page-body .wp-block-button :hover {
    color: #000000;
}

#post-page-body .wp-block-button :active {
    filter: brightness(90%);
}

#post-page-body .wp-block-button__link {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

#post-page-body .wp-block-button__link :hover {
    color: #000000;
}

#post-page-body .wp-block-button__link :active {
    filter: brightness(90%);
}

.categories-list {
    text-align: left;
}

.categories-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

#post-page-categorization {
    color: #05373D;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.5px;
    padding-bottom: 10px;
}

#post-page-category {
    text-align: left;
    color: #08545c;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.5px;
    padding-left: 30px;
}

/* ----------------------- MOBILE ----------------------- */

@media (max-width: 768px) {
    .content.news {
        width: 95% !important;
        margin-left: 2.5% !important;
    }

    #post-page-title {
        padding-top: 10px;
        color: #0F767E;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    #post-page-author {
        padding-top: 5px;
        color: #05373D;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 20px;
    }

    #post-page-body {
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: justify;
    }

    #post-page-body figure img {
        max-width: 100%;
    }
}