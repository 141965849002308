.post-card {
    border: none;
    max-width: 450px;
    max-height: 700px;
    padding: 2%;
}

@media only screen and (max-width: 767px) {
    .post-card {
        border: none;
        max-width: 100%;
        max-height: 700px;
        padding: 2%;
    }
}

#post-card-img {
    width: 100%;
    height: 300;
    border-radius: 20px;
    text-align: left;
}

#post-card-title {
    padding-top: 20px;
    color: #05373D;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
}

#post-card-date {
    color: #5E6162;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

#post-card-link {
    color: #05373D;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    text-decoration: none;;
}

#post-card-link:hover {
    color: #0198A6;
}

#post-card-link:active {
    filter: brightness(90%);
}