.posts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.posts-list-home {
    margin-left: -5%;
    max-width: 75%;
    display: grid;
    grid-template-columns: 2fr 1.3fr;
    grid-gap: 10px;
}

.posts-list-home>div {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.posts-list-home>.first-column {
    flex: 2;
}

.posts-list-home-mobile {
    margin-left: -5%;
    max-width: 150%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.posts-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.posts-row .post-card {
    width: 30%;
}

.posts-last-row {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.posts-last-row .post-card {
    width: 30%;
    margin-right: 62px;
}

.white-hr {
    border: 1px solid white;
}

.posts-list form button {
    background-color: #25b8c7;
    border: 1px solid #25b8c7;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 10px 0;
    cursor: pointer;
    font-weight: bold;
    color: white;
}

@media (max-width: 768px) {
    .posts-row {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .posts-row .post-card {
        width: 100%;
    }

    .posts-last-row .post-card {
        width: 100%;
    }
}