.up-footer {
    background-color: white;
    height: 112px;
}

.up-footer .left {
    font-size: 13px;
    line-height: 16.5px;
    font-weight: 500;
    width: 700px;
    float: left;
    padding-right: 60px;
    position: relative;
    padding-top: 27px;
    padding-bottom: 24px;
    padding-left: 70px;
}

.up-footer .right {
    width: 400px;
    float: left;
}

.up-footer .right img {
    height: 55px !;
    padding-top: 21px;
    padding-bottom: 21px;
    width: 260px;
}

.sub-footer {
    background-color: #003A41;
    height: 48px;
}

.sub-footer .left,
.sub-footer .right {
    width: calc(50%);
    float: left;
    color: white;
    font-size: 14px;
    line-height: 28px;
    padding-top: 10px;
}

.sub-footer .right {
    text-align: right;
    padding-left: 0px;
}

.sub-footer .right a {
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    text-decoration: none;
}

.sub-footer .right a:last-child {
    padding-right: 0px;
}

.sub-footer .right a:hover {
    color: #25b8c7;
}

.sub-footer .right a:active {
    color: #1a7d88;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {
    .up-footer {
        height: auto;
    }

    .up-footer .left {
        padding-top: 20px;
        color: #05373D;
        font-weight: 500;
        line-height: 16.5px;
        width: 86%;
        margin-left: 7%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .up-footer .right {
        width: 86%;
        margin-left: 7%;
    }

    .up-footer .right img {
        height: auto;
        padding-top: 0px;
        padding-bottom: 20px;
        width: 250px;
        padding-left: 10px;
    }

    .sub-footer {
        background-color: #003A41;
    }

    .mobile-footer-links {
        padding-top: 10px;
        text-align: center;
        color: white;
        font-size: 10px;
    }

    .sub-footer {
        height: auto;
    }

    .sub-footer .content {
        display: flex; 
        flex-direction: column;
    }

    .sub-footer a {
        padding-left: 10px;
        padding-right: 10px;
        color: white;
        font-size: 14px;
        text-decoration: none;
    }

    .sub-footer a:hover {
        color: #25b8c7;
    }

    .sub-footer a:active {
        color: #1a7d88;
    }

    .sub-footer .left {
        color: white;
        font-size: 11px;
        padding-top: 6px;
        text-align: center;
        padding-bottom: 10px;
        width: 100%;
        order: 2;
    }

    .sub-footer .right{
        width: 100% !important;
        text-align: center;
        order: 1;
        padding-top: 14px;
    }
}