#title .title-title {
    padding-bottom: 50px;
}

.application-banner-nov-2023 {
    margin-bottom: 60px;
}

.application-banner-nov-2023 .open-calls-content .open-calls-2-nov-2023 {
    padding-top: 80px;
}

#current-calls-objectives-2 {
    background: url(../../images/current-calls/current_calls_bg_2.svg) no-repeat;
    background-color: #05373D;
    background-size: contain;
    background-position: left;
    text-align: center;
    min-height: 400px;
}

#current-calls-objectives-2 .content .title {
    margin-top: 7%;
    margin-bottom: 2%;
    color: #A0F9FF;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.5px;
}

#current-calls-objectives-2 .content .text {
    max-width: 80%;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.5px;
}

.objective-button {
    margin-top: 40px;
    text-align: center;
}

.application-separator {
    padding-bottom: 20px;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}

#application-section-8 {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.5px;
    text-align: left;
    color: #05373D;
    margin-bottom: 20px;
    ;
}