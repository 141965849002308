.page-container {
    overflow: visible;
}

.header-news {
    background: url(../../images/events/events_bg_1.svg) no-repeat;
    background-size: contain;
    background-position: 90% 10%;
    overflow: visible;
    margin-bottom: 0;
    min-height: 200px;
}

#news-title {
    color: #05373D;
    font-size: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 11px;
    text-transform: uppercase;
    overflow: visible;
}

#categories-breadcrumb {
    color: #5E6162;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.5px;
    margin-top: 2%;
    margin-bottom: 2%;
}

.categories-banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.categories-column-1 {
    grid-column: 1;
    display: flex;
}

.categories-column-2 {
    grid-column: 2;
    display: flex;
    white-space: nowrap;
    justify-content: flex-end;
}

#follow-us-txt {
    color: #05373D;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 4.5px;
    text-transform: uppercase;
    margin-right: 5%;
}

#categories-txt {
    color: #5E6162;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#categories-txt,
.category-links {
    display: inline-block;
}

.categories-button-circular {
    align-items: center;
    background-color: #11B5C0;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    align-items: center;
    text-align: center;
    height: fit-content;
    width: 43px;
    padding: 1%;
    margin-right: 2%;
}

.categories-button-circular:active {
    filter: brightness(90%);
}

.categories-icon {
    color: #FFFFFF;
}

.categories-icon:hover {
    color: #000000;
}

.categories-braker {
    margin-top: 1%;
    width: 100%;
    height: 1px;
    background-color: #C4C4C4;
}

.category-link {
    color: #05373D;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    margin-left: 20px;
    text-decoration: none;
    cursor: pointer;
}

.category-link:hover {
    color: #1f8c98;
}

.category-link:active {
    filter: brightness(80%);
}

.news-banner {
    min-height: 500px;
    background: url(../../images/events/events_bg_2.svg) no-repeat;
    background-size: contain;
    background-position: left;
}

/* ----------------------- MOBILE ----------------------- */

@media only screen and (max-width: 768px) {

    #news-banner .content #news-title {
        color: #05373d;
        text-align: left;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 6px;
        position: relative;
        overflow: visible;
    }

    #categories-breadcrumb .content {
        color: #5E6162;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    .categories-banner {
        display: flex;
        flex-direction: column;
    }

    #follow-us-txt {
        width: 260px;
        margin-right: 40px;
    }

    .categories-button-circular {
        align-items: center;
        background-color: #11B5C0;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        align-items: center;
        text-align: center;
        height: 40px;
        width: 40px;
        padding: 1%;
        margin-right: -85px;
    }

    .categories-column-2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    #categories-txt {
        margin-bottom: -5px;
    }

    .category-links {
        margin-left: -10px;
        margin-bottom: -10px;
    }

    .category-link {
        color: #05373D;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        margin-left: 10px;
    }

}